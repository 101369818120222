import { eventBus } from '@/utils/plugins/event-bus';
import { $log } from '@/utils/plugins/logger';
import { ProductBDService } from '@services/product-db';
import { store } from '@/store';

function openModal() {
  eventBus.emit('eb_open_promocode_gift_modal');
}

/**
 * Тут мы получаем все инфу товаров по айдишникам которые были в объекте семплов
 * и сохраняем их в store ProductDBModule для кеширования
 * @param {PromocodeResponse} promocodeRes семплы
 * @returns
 */
async function loadGiftProduct({ id, promocodeId, wId, price }) {
  const alreadySeen = store.getters['promocodes/alreadySeenGiftProduct'];

  $log(
    'loadGiftProduct',
    { id, promocodeId, wId, alreadySeen },
    'PromocodeGiftService'
  );

  const products = await ProductBDService.loadProductsByIds([id], wId);
  const selectedProduct = products?.[0]
    ? {
        ...(products?.[0] || {}),
        old_price: products?.[0].price,
        price,
      }
    : null;

  const hasQuantity = selectedProduct?.max_quantity > 0;
  const isAlreadySeen =
    alreadySeen.id === id &&
    alreadySeen.promocodeId === promocodeId &&
    alreadySeen.hasQuantity === hasQuantity;
  if (isAlreadySeen) {
    return;
  }

  if (selectedProduct && !isAlreadySeen) {
    openModal();
  }

  store.commit('promocodes/SET_ALREADY_SEEN_GIFT_PRODUCT', {
    id,
    hasQuantity,
    promocodeId,
  });

  $log('loadGiftProduct', { product: selectedProduct }, 'product');
  store.commit('promocodes/SET_PROMOCODE_GIFT_PRODUCT', selectedProduct);
}

function resetGiftProduct() {
  store.commit('promocodes/SET_ALREADY_SEEN_GIFT_PRODUCT', {});
  store.commit('promocodes/SET_PROMOCODE_GIFT_PRODUCT', null);
}

async function applyGiftProduct(data, wId) {
  if (!data) {
    PromocodeGiftService.resetGiftProduct();
    return;
  }

  const { promoProductId, promoProductPrice, promocodeId } = data;

  if (promoProductId) {
    await loadGiftProduct({
      id: promoProductId,
      promocodeId,
      wId,
      price: promoProductPrice,
    });
  } else {
    resetGiftProduct();
  }
}

export const PromocodeGiftService = {
  loadGiftProduct,
  resetGiftProduct,
  applyGiftProduct,
  openModal,
};
