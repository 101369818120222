import { $log } from '@/utils/plugins/logger';

export function getDynamicYandexApiKey() {
  const currentHour = new Date().getHours();
  $log('YMAPS: current hour: ', { currentHour: currentHour });

  if (currentHour >= 22 || currentHour < 4) {
    $log('YMAPS: night api');
    return import.meta.env.VITE_YANDEX_MAP_API_KEY_NIGHT;
  }
  $log('YMAPS: default api');
  return import.meta.env.VITE_YANDEX_MAP_API_KEY;
}

// Мокаем время для тестов
function mockTime(mockHour) {
  const RealDate = Date;

  window.Date = class extends RealDate {
    constructor(...args) {
      super();
      if (args.length === 0) {
        // Создаем дату с заданным часом
        const date = new RealDate();
        date.setHours(mockHour, 0, 0, 0);
        return date;
      }
      return new RealDate(...args);
    }

    static now() {
      const now = new RealDate();
      now.setHours(mockHour, 0, 0, 0);
      return now.getTime();
    }
  };

  // Функция для восстановления оригинального Date
  return () => {
    window.Date = RealDate;
  };
}

// Тестируем метод
export function testGetDynamicYandexApiKey(mockHour) {
  const restoreDate = mockTime(mockHour);

  try {
    $log(`YMAPS: Тест при ${mockHour}:00`);
    const apiKey = getDynamicYandexApiKey();
    $log('YMAPS: Полученный API ключ:', apiKey);
  } finally {
    restoreDate(); // Восстанавливаем оригинальный Date
  }
}
