<template>
  <form
    :id="id"
    class="AddressForm address-form"
    :class="{ '--desk': $isDesktop }"
    @submit.prevent="onSubmit"
  >
    <template v-if="!isPrivateHouse">
      <!-- Квартира/офис -->
      <UiTextField
        v-model="$v.flat.$model"
        :size="$isDesktop ? 'M' : 'md'"
        class="address-form-input"
        :error-message="$t('requiredField')"
        :label="$t($isDesktop ? 'flatOfficeDesk' : 'flatOffice')"
        name="flat"
        type="text"
        is-required
        maxlength="10"
        clearable
        clear-btn="sm"
        :invalid="$v.flat.$error"
        @onFocus="$emit('onFocus')"
      />
      <!-- Подъезд -->
      <UiTextField
        v-model="$v.entrance.$model"
        :size="$isDesktop ? 'M' : 'md'"
        class="address-form-input"
        :error-message="$t('requiredField')"
        :label="$t('entrance')"
        name="entrance"
        type="text"
        is-required
        maxlength="5"
        clearable
        clear-btn="sm"
        transform-uppercase
        transform-trim
        :transform-regexp="/[^A-Za-z0-9\\\/]/g"
        :invalid="$v.entrance.$error"
        @onFocus="$emit('onFocus')"
      />

      <!-- Этаж -->
      <UiTextField
        v-model="$v.floor.$model"
        :size="$isDesktop ? 'M' : 'md'"
        class="address-form-input"
        :error-message="
          !$v.floor.required
            ? $t('requiredField')
            : !$v.floor.validateFloor
              ? $t('floorError')
              : ''
        "
        :label="$t('floor')"
        name="floor"
        type="number"
        is-required
        maxlength="2"
        min="-5"
        max="88"
        clearable
        clear-btn="sm"
        :invalid="$v.floor.$error"
        @onFocus="$emit('onFocus')"
      />

      <!-- Код домофона -->
      <UiTextField
        v-model="$v.doorbell.$model"
        :size="$isDesktop ? 'M' : 'md'"
        class="address-form-input"
        :error-message="$t('requiredField')"
        :label="$t($isDesktop ? 'doorbellDesk' : 'doorbell')"
        name="doorbell"
        maxlength="60"
        type="text"
        :invalid="$v.doorbell.$error"
        @onFocus="$emit('onFocus')"
      />
    </template>

    <UiTextarea
      v-model="$v.comment.$model"
      class="address-form-input"
      :size="$isDesktop ? 'M' : 'md'"
      name="message"
      :label="$t('commentForCourier')"
      maxlength="460"
      :rows="2"
      no-resize
      @onFocus="$emit('onFocus')"
    />

    <UiTextField
      v-if="withName"
      v-model="$v.name.$model"
      :size="$isDesktop ? 'M' : 'md'"
      :label="$t('addressName')"
      class="address-form-input address-form-name"
      name="name"
      type="text"
      :invalid="$v.name.$error"
      @onFocus="$emit('onFocus')"
    />
  </form>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

import UiTextField from '@pure-ui/components/UiTextField/UiTextField.vue';
import UiTextarea from '@pure-ui/components/UiTextArea/UiTextArea.vue';

export default {
  name: 'AddressForm',
  components: {
    UiTextField,
    UiTextarea,
  },
  mixins: [validationMixin],
  inject: ['toast'],
  props: {
    id: {
      type: String,
      required: true,
    },
    initialState: {
      type: Object,
      default: null,
    },
    isPrivateHouse: {
      type: Boolean,
      default: false,
    },
    withName: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      flat: '',
      entrance: '',
      floor: '',
      comment: '',
      doorbell: '',
      name: '',
    };
  },
  computed: {
    isFormValid() {
      if (this.isPrivateHouse) return true;

      return [
        this.$v.flat.$model,
        this.$v.entrance.$model,
        this.$v.floor.$model,
        !this.$v.$invalid,
      ].every(v => !!v);
    },
  },
  watch: {
    initialState: {
      deep: true,
      handler() {
        this.initState();
      },
    },
    isFormValid: {
      immediate: true,
      handler(v) {
        this.$emit('onFormValidChange', v);
      },
    },
  },
  validations: {
    flat: {
      required,
    },
    entrance: {
      required,
    },
    floor: {
      required,
      validateFloor: v => {
        const intgr = parseInt(v, 10);
        const regexpPass = /^-?\d+$/.test(v);
        const valPass = intgr >= -5 && intgr <= 88;
        return regexpPass && valPass;
      },
    },
    doorbell: {},
    comment: {},
    name: {},
  },
  mounted() {
    if (this.initialState) {
      this.$nextTick(() => {
        this.initState();
      });
    }
  },
  methods: {
    initState() {
      const toString = x => (x !== null && x !== undefined ? x.toString() : '');
      this.flat = toString(this.initialState.flat_number);
      this.entrance = toString(this.initialState.entrance);
      this.floor = toString(this.initialState.floor);
      this.comment = toString(this.initialState.comment);
      this.doorbell = toString(this.initialState.doorbell);
      this.name = toString(this.initialState.name);
    },
    onSubmit() {
      this.$v.$touch();

      if ((this.$v.$invalid || this.$v.floor.$error) && !this.isPrivateHouse) {
        this.toast.show(this.$t('fillAll'));
        return;
      }

      this.$emit('onSubmit', {
        entrance: this.entrance,
        flat_number: this.flat,
        floor: this.floor,
        comment: this.comment,
        doorbell: this.doorbell,
        name: this.name,
      });
    },
  },
  i18n: {
    messages: {
      ru: {
        requiredField: 'Обязательное поле',
        floorError: 'Этаж от -5 до 88',
        flatOffice: 'Кв/офис',
        flatOfficeDesk: 'Кв/офис',
        entrance: 'Подъезд',
        floor: 'Этаж',
        doorbell: 'Код домофона',
        doorbellDesk: 'Домофон',
        commentForCourier: 'Комментарий для курьера',
        fillAll: 'Заполните обязательные поля: квартира, подъезд и этаж',
        enterEntranceNumber: 'Введите номер подьезда в цифрах',
        addressName: 'Название адреса, например «Дом», «Работа»',
        tooLong: 'Введите корректные данные',
      },
      kk: {
        requiredField: 'Міндетті жол',
        floorError: '-5-тен 88-ге дейін',
        flatOffice: 'Пәтер, кеңсе',
        flatOfficeDesk: 'Пт/кеңсе',
        entrance: 'Кіреберіс',
        floor: 'Қабат',
        doorbell: 'Домофон коды',
        doorbellDesk: 'Домофон',
        commentForCourier: 'Курьерге комментарий',
        fillAll:
          'Барлық міндетті жолақтарды толтырыңыз: пәтер, кіреберіс ж/ә қабат',
        enterEntranceNumber: 'Кіреберіс нөмірін сандармен енгізіңіз',
        addressName: 'Мекен-жай аты, мысалға "Уй", "Жұмыс"',
        tooLong: 'Дұрыс ақпарат толтырыңыз',
      },
    },
  },
};
</script>

<style scoped>
.address-form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
}
.address-form-input {
  @apply w-full;
}
.address-form-input:last-child {
  grid-column: 1 / 3;
}
.--desk.address-form {
  grid-template-columns: repeat(4, 1fr);
  gap: 12px;
}
.--desk .address-form-input:nth-last-child(1),
.--desk .address-form-input:nth-last-child(2) {
  @apply w-full;
  grid-column: 1 / 5;
}
</style>
