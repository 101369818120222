<template>
  <GiftsProductCard
    class="SamplingProduct"
    :product="product"
    :sticker="sticker"
    @onGoProduct="$emit('onGoProduct', 'block')"
  >
    <template #photo>
      <SamplingPhoto
        class="SamplingProduct__photo"
        :class="`${sticker ? 'mr-1' : ''}`"
        :src="product.path"
        :price="product.price"
        :sticker="sticker"
        @click.native.stop="$emit('onGoProduct', 'photo')"
      />
    </template>
  </GiftsProductCard>
</template>

<script>
import SamplingPhoto from '@pure-ui/components/Sampling/SamplingPhoto/SamplingPhoto.vue';
import GiftsProductCard from '@pure-ui/components/Gifts/GiftsProductCard/GiftsProductCard.vue';

export default {
  name: 'SamplingProduct',
  components: {
    GiftsProductCard,
    SamplingPhoto,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    sticker: Boolean,
  },
};
</script>

<style scoped>
.SamplingProduct__photo {
  @apply bg-white;
}
</style>
