import cloneDeep from 'lodash.clonedeep';
import { Logger as LOG } from '@shared/services/logger';
import {
  rFetchActiveBanners,
  rFetchCategoryBanners,
} from '@shared/services/api/requestsV2/banners';
import { extractBanners } from '@services/banners/adapters/extract-banners';
import { shuffleArray } from '@/utils/functions/shuffle-array';
import { store } from '@/store';
import { WatchedBanners } from './watched-banners';
import { StaticBanners } from './static-banners';
import { eventBus } from '@/utils/plugins/event-bus';

const watchedBannersCodes = WatchedBanners.get();
let categoryBannersLoaded = false;

/**
 * При первом посещении страницы категории
 * загружает баннеры со сториз для категорий
 * и сохраняет в сторе
 */
function loadCategoryBannersToStore() {
  return new Promise(resolve => {
    if (categoryBannersLoaded) {
      resolve({});
    }
    return rFetchCategoryBanners()
      .then(data => {
        categoryBannersLoaded = true;
        if (Array.isArray(data.data)) {
          const result = {};
          const banners = extractBanners(data.data);
          banners.forEach(v => {
            result[v.categoryId] = v;
          });
          store.commit('banners/SET_CATEGORY_BANNERS', result);
          return resolve({});
        }
        return resolve();
      })
      .catch(err => {
        LOG.event('fetchCategoryBanner err', '', JSON.stringify(err));
        resolve();
      });
  });
}

function setWatched(banner) {
  const banners = cloneDeep(store.getters['banners/banners']);
  banners.find(v => v.code === banner.code).watched = true;
  WatchedBanners.append(banner.code);
  store.commit('banners/SET_BANNERS', banners);
}

/**
 * Перемешивает элементы. Те, у которых есть свойство fixedPosition,
 * остаются на месте
 */
function shuffle(banners) {
  const fixed = banners
    .map((v, i) => ({ value: v, index: i }))
    .filter(v => v.value.fixedPosition);

  const shuffled = shuffleArray(banners.filter(v => !v.fixedPosition));
  fixed.forEach(v => {
    shuffled.splice(v.index, 0, v.value);
  });
  return shuffled;
}

function fetchBanners() {
  return rFetchActiveBanners({}, false).then(activeBanners => {
    return {
      banners: extractBanners(activeBanners.data),
    };
  });
}

function getMarkedWatchedBanners(banners) {
  return banners.map(v => ({
    watched: watchedBannersCodes.includes(v.code),
    ...v,
  }));
}

/**
 * Загрузка и сохранение баннеров главной страницы
 */
function loadBannersToStore() {
  return fetchBanners()
    .then(({ banners: b }) => {
      const banners = [];

      if (store.getters['user/hasReferralProgram']) {
        banners.push(StaticBanners.referralBanner);
      }

      if (store.getters['cashbacks/hasCashbacksProgram']) {
        banners.push(StaticBanners.cashbacksBanner);
      }

      banners.push(...b);

      /**
       * Катя из коммерции попросила пока убрать, т. к. поставщики
       * не довольны тем, что сториз слишком много
       */
      // banners.push(...Object.values(StaticBanners.faq));

      return banners;
    })
    .then(b => {
      const banners = getMarkedWatchedBanners(b);
      LOG.event('loadBannersToStore loaded', banners);
      store.commit('banners/SET_BANNERS', banners);
      return banners;
    })
    .catch(() => []);
}

function init() {
  return loadBannersToStore().finally(() => {
    eventBus.on('eb_on_city_changed', loadBannersToStore);
  });
}

export const BannersService = {
  init,
  loadCategoryBannersToStore,
  setWatched,
  shuffle,
};
