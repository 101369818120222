<template>
  <DPromocodeField v-if="$isDesktop" v-bind="state" v-on="listeners" />
</template>

<script>
import { mapGetters } from 'vuex';
import { CartService } from '@services/cart';
import { PromocodeGiftService } from '@services/promocode-gift';
import { AddressEmitter, ADDRESS_EVENTS } from '@services/address/emitter';
import DPromocodeField from '@pure-ui/components/DPromocodeField/DPromocodeField.vue';
import { PromocodesService } from '@services/promocodes';
import { Logger } from '@shared/services/logger';
import { DeliveryConfigService } from '@services/delivery-config';
import { CartEmitter, CART_EVENTS } from '@services/cart/events';
import { Analitycs, EVENTS } from '@shared/services/analitycs';

function defaultState() {
  return {
    open: false,
    promocode: '',
    notAppliedWithErrorText: '',
    appliedWithErrorText: '',
    appliedWithWarningText: '',
    appliedDiscount: 0,
    appliedFreeDelivery: false,
    freeDeliveryNotApplied: false,
    giftProduct: false,
    giftProductOutOfStock: false,
  };
}

export default {
  name: 'PromocodeDesktop',
  components: {
    DPromocodeField,
  },
  data: () => ({
    state: defaultState(),
  }),
  computed: {
    ...mapGetters('promocodes', [
      'promocodeGiftProduct',
      'giftProductOutOfStock',
    ]),
    ...mapGetters('cart', ['promocode']),
    listeners() {
      return {
        onInput: $event => {
          if (this.state.appliedDiscount) {
            CartService.resetPromocode();
          }
          this.setState('Open', {
            open: true,
            promocode: $event,
          });
        },
        onSwitch: $event => {
          if ($event) {
            this.setState('Open', { open: true });
          } else {
            this.setState('Default');
            CartService.resetPromocode();
          }
        },
        onSubmit: this.onSubmit,
      };
    },
  },
  watch: {
    promocode(value) {
      if (!value) {
        this.setState('Default');
      }
    },
  },
  mounted() {
    CartEmitter.on(CART_EVENTS.ON_CART_CHANGED, this.reapplyPromocode);
    CartEmitter.on(CART_EVENTS.ON_CART_FETCHED, this.reapplyPromocode);
    AddressEmitter.on(ADDRESS_EVENTS.ON_ADDRESS_CHANGE, this.reapplyPromocode);
    if (this.$store.getters.isAuthorized) {
      this.init();
    }
  },
  beforeDestroy() {
    CartEmitter.off(CART_EVENTS.ON_CART_CHANGED, this.reapplyPromocode);
    CartEmitter.off(CART_EVENTS.ON_CART_FETCHED, this.reapplyPromocode);
    AddressEmitter.off(ADDRESS_EVENTS.ON_ADDRESS_CHANGE, this.reapplyPromocode);
  },
  methods: {
    init() {
      if (!this.$route.name === 'cart') return Promise.resolve();
      /*
        Пока не работает. Появится, когда найдём способ
        передавать в реферальной ссылке промокод,
        чтобы сразу открывалась страница
      */
      // if (ReferralService.hasPromocode()) {
      //   this.apply(ReferralService.getPromocode());
      // }
      return PromocodesService.loadActiveToStore().then(() => {
        PromocodesService.applyClosestIfNo();
        if (PromocodesService.hasAppliedPromocode()) {
          return this.apply(PromocodesService.getAppliedPromocode());
        }
        return Promise.resolve();
      });
    },
    onReapply($event) {
      this.apply($event);
      if (!$event && !$event.length) {
        return;
      }
      PromocodesService.savePromocode($event).catch(err => {
        Logger.event('promocode save error', err);
      });
    },
    onSubmit($event) {
      this.apply($event, 'submit');
      if (!$event && !$event.length) {
        return;
      }
      PromocodesService.savePromocode($event).catch(err => {
        Logger.event('promocode save error', err);
      });
    },
    /**
     *
     * @param {*} promocode
     * @param {*} action если передана строка submit — значит метод запущен после
     * ввода промокода руками
     */
    apply(promocode, action) {
      if (this.$store.getters['cart/cartLoading']) {
        return;
      }
      DeliveryConfigService.setFreeDeliveryPromocode('');
      // console.log('PromocodeDesktop.onSubmit', promocode);
      if (!promocode) {
        CartService.resetPromocode();
        PromocodeGiftService.resetGiftProduct();
        this.setState('Default');
        return;
      }
      console.log('apply', action);
      if (action === 'submit') PromocodesService.setLoader(true);

      CartService.applyPromocode(promocode)
        .then(r => {
          if (!r) {
            return;
          }

          const isDeliveryFreeAlready =
            this.$store.getters['paidOrder/isDeliveryFreeReached'];
          // если был применён промокод на бесплатную
          if (r.data.cartPromocodeData.freeDelivery) {
            // если условия для бесплатной доставки уже выполнены
            // промокод на бесплатную тратить нет смысла
            if (isDeliveryFreeAlready) {
              this.setState('FreeDeliverySuccess', {
                open: true,
                promocode,
                freeDeliveryNotApplied: true,
              });
              // промокод на бесплатную применён
            } else {
              this.setState('FreeDeliverySuccess', {
                open: true,
                promocode,
                appliedFreeDelivery: true,
              });
              DeliveryConfigService.setFreeDeliveryPromocode(promocode);
            }
            // обычный промокод на скидку успешно применён
          } else {
            this.setState('Success', {
              open: true,
              promocode,
              appliedDiscount: r.data.discount,
              giftProduct: !!r.data.cartPromocodeData?.promoProductId,
              giftProductOutOfStock:
                this.giftProductOutOfStock ||
                (r.data.cartPromocodeData?.promoProductId &&
                  !this.promocodeGiftProduct),
            });
          }

          Analitycs.logEvent(EVENTS.PRODUCT_PROMOCODE_APPLIED, {
            promocode: promocode,
            product_id: r.data?.cartPromocodeData?.promoProductId,
            product_price: r.data?.cartPromocodeData?.promoProductPrice,
            error: this.giftProductOutOfStock ? 'Out of stock' : '',
          });

          if (action === 'submit') {
            Analitycs.logEvent(EVENTS.CART_PROMOCODE_USER_ACTION, {
              action: 'success',
              promocode,
            });
          }
        })
        .catch(e => {
          // Если промокод валиден, но не все условия выполнены
          if (e.errorData.conditions_failed) {
            this.setState('AppliedWithError', {
              open: true,
              promocode,
              appliedWithErrorText: e.message,
            });
            // промокод в любом случае нельзя применить
          } else {
            this.setState('notAppliedWithErrorText', {
              open: true,
              promocode,
              notAppliedWithErrorText: e.message,
            });
            if (action === 'submit') {
              Analitycs.logEvent(EVENTS.CART_PROMOCODE_USER_ACTION, {
                action: 'error',
                promocode,
                error: e.message,
              });
            }
          }

          Analitycs.logEvent(EVENTS.PRODUCT_PROMOCODE_APPLIED, {
            promocode: promocode,
            error: e?.message || '',
          });
        })
        .finally(() => {
          PromocodesService.setLoader(false);
        });
    },
    reapplyPromocode() {
      if (
        this.state.promocode &&
        (this.state.appliedDiscount ||
          this.state.appliedWithErrorText ||
          this.state.appliedFreeDelivery ||
          this.state.freeDeliveryNotApplied ||
          this.state.giftProduct)
      ) {
        this.onReapply(this.state.promocode);
      }
    },
    /**
     *
     * @param {*} stateName Просто для понимания, названия из сторибука
     * @param {*} params
     */
    setState(stateName, params) {
      this.state = {
        ...defaultState(),
        ...params,
      };
    },
  },
};
</script>
