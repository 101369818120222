<template>
  <div class="CancelOrderReasonModal">
    <UiPopup
      :show="modelValue"
      closable
      :radius="$isDesktop ? '3xl' : '4xl'"
      :is-full-page="!$isDesktop"
      :is-back-button="!$isDesktop"
      style="--popup-width-d: 588px"
      @change="$emit('update:modelValue', $event)"
      @onTriggeredModalClose="$emit('onTriggeredModalClose')"
    >
      <div class="cancel-modal">
        <p class="cancel-modal-title" v-html="$t('orderCanceled')"></p>
        <p class="cancel-modal-descr" v-html="$t('orderReason')"></p>

        <UiRadio
          v-bind="{
            modelValue: selected,
            radios: reasons,
            valueId: 'reason_id',
            text: `reason_${$i18n.locale}`,
          }"
          @update:modelValue="select($event)"
        />

        <UiTextArea
          v-if="selected && selected.reason_id === 14"
          :value="comment"
          :label="$t('writeReason')"
          rows="4"
          name="comment"
          class="cancel-modal-comment"
          no-resize
          @onInput="$emit('onInputComment', $event)"
        />
        <UiButton
          :size="!$isDesktop ? 'M' : 'XL'"
          :disabled="
            !selected || (selected && selected.reason_id === 14 && !comment)
          "
          class="cancel-modal-btn"
          @click="submit"
        >
          {{ $t('done') }}
        </UiButton>
      </div>
    </UiPopup>
  </div>
</template>

<script>
import UiPopup from '@pure-ui/components/UiPopup/UiPopup.vue';
import UiRadio from '@pure-ui/components/UiRadio/UiRadio.vue';
import UiButton from '@pure-ui/components/UiButton/UiButton.vue';
import UiTextArea from '@pure-ui/components/UiTextArea/UiTextArea.vue';

export default {
  name: 'CancelButton',
  components: {
    UiTextArea,
    UiButton,
    UiPopup,
    UiRadio,
  },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  props: {
    modelValue: Boolean,
    reasons: {
      type: Array,
      default: () => [],
    },
    comment: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selected: null,
    };
  },
  methods: {
    select(reason) {
      this.selected = reason;
      this.$emit('onUpdateReason', this.selected);
    },
    submit() {
      if (this.selected && this.selected.reason_id === 14 && !this.comment)
        return;
      this.$emit('onSubmitReason', this.selected);
    },
  },
  i18n: {
    messages: {
      ru: {
        done: 'Готово',
        orderCanceled: 'Заказ отменен. <br /> Укажите причину',
        orderReason:
          'Пожалуйста, укажите причину отмены, мы&nbsp;хотим исправиться, если как-то вас&nbsp;подвели',
        writeReason: 'Укажите причину',
      },
      kk: {
        done: 'Жалғастыру',
        orderCanceled: 'Тапсырыс қайтарылды. <br /> Себебін таңдау',
        orderReason:
          'Қайтару себебін таңдауыңызды сұраймыз, көңіліңізді қалдырған кезіміз болса, түзелгіміз келеді',
        writeReason: 'Қайтару себебі',
      },
    },
  },
};
</script>

<style scoped>
.cancel-modal {
  @apply mt-7 pb-8
    md:mt-2 md:pl-0 md:pt-0 md:pb-0 md:pr-0
    flex flex-col h-full;
}
.cancel-modal-title {
  @apply font-bold text-2xl
    md:text-2.5 md:leading-8;
}
.cancel-modal-descr {
  @apply mt-5 mb-4 
    md:mt-6 md:mb-5
    leading-6
    md:text-lg;
}
.cancel-modal-comment {
  @apply mt-3 font-medium leading-18;
}
.cancel-modal-btn {
  @apply mt-auto md:mt-8;
}
</style>
