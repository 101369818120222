<template>
  <GiftsPhoto class="SamplingPhoto" :src="src" :sticker="sticker" :size="size">
    <p v-if="sticker" class="SamplingPhoto__price">{{ price }} ₸</p>
  </GiftsPhoto>
</template>

<script>
import GiftsPhoto from '@pure-ui/components/Gifts/GiftsPhoto/GiftsPhoto.vue';

export default {
  name: 'SamplingPhoto',
  components: {
    GiftsPhoto,
  },
  props: {
    price: {
      type: Number,
      default: 1,
    },
    src: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'sm',
    },
    sticker: Boolean,
  },
};
</script>

<style scoped></style>
