<template>
  <div class="AddressNameModal">
    <UiBottomSheetWrapper
      :show="modelValue"
      with-title
      with-back-button
      @change="$emit('update:modelValue', $event)"
    >
      <template #title>{{ $t('addressName') }}</template>

      <div class="modal-content">
        <p class="modal-subtitle">{{ $t('provideAddressName') }}</p>

        <UiTextField
          :value="name"
          class="modal-input"
          :label="$t('addressNamePlaceholder')"
          maxlength="30"
          name="nameField"
          clearable
          autofocus
          @onInput="onInput"
        />

        <UiButton
          class="modal-button"
          size="lg"
          form="new-address-from"
          type="button"
          @click="onSubmit"
        >
          {{ $t('saveAddress') }}
        </UiButton>
      </div>
    </UiBottomSheetWrapper>
  </div>
</template>

<script>
import UiBottomSheetWrapper from '@pure-ui/components/UiBottomSheetWrapper/UiBottomSheetWrapper.vue';
import UiTextField from '@pure-ui/components/UiTextField/UiTextField.vue';
import UiButton from '@pure-ui/components/UiButton/UiButton.vue';

export default {
  name: 'AddressNameModal',
  components: {
    UiBottomSheetWrapper,
    UiTextField,
    UiButton,
  },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  props: {
    modelValue: {
      type: Boolean,
      default: true,
    },
    name: {
      type: String,
      default: '',
    },
  },
  methods: {
    close() {
      this.$emit('update:modelValue', false);
    },
    onSubmit() {
      this.close();
      this.$emit('onSubmit', this.name);
    },
    onInput(value) {
      this.$emit('update:name', value);
    },
  },
  i18n: {
    messages: {
      kk: {
        addressName: 'Мекенжай атауы',
        provideAddressName:
          'Өзіңіздің мекенжайларыңызды шатастырмау үшін оларға атау беріңіз',
        addressNamePlaceholder: 'Мекенжай аты, мысалға “Үй”, “Жұмыс”',
        saveAddress: 'Мекенжайды сақтау',
      },
      ru: {
        addressName: 'Название адреса',
        provideAddressName:
          'Укажите название, чтобы легко ориентироваться между своими адресами',
        addressNamePlaceholder: 'Название адреса, например «Дом», «Работа»',
        saveAddress: 'Сохранить адрес',
      },
    },
  },
};
</script>

<style scoped>
.modal-content {
  @apply mt-4;
}
.modal-subtitle {
  @apply mt-6 leading-5;
}
.modal-input {
  @apply mt-4;
}
.modal-button {
  @apply w-full font-bold text-lg mt-7;
}
</style>
