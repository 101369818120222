import dayjs from 'dayjs';
import { Analitycs, EVENTS } from '@shared/services/analitycs';
import { RahmetApp } from '@shared/RahmetApp';
import { eventBus } from '@/utils/plugins/event-bus';
import { DevConfigs } from '@/utils/dev-config';
import { createStorage } from '@shared/services/storage';

const storage = createStorage(window.localStorage);

/**
 * Считаем, что билд устарел, если прошло
 * больше 2 недель
 */
function isOutdated() {
  const now = dayjs();
  const releaseDate = import.meta.env.VITE_APP_RELEASE_TIME;

  const diff = now.diff(releaseDate, 'week', true);
  return diff >= 2;
}

function updateWebview() {
  Analitycs.logEvent(EVENTS.OUTDATED_BUILD);
  if (typeof window.RahmetApp?.reloadPage === 'function') {
    console.log('updateWebview reloadPage WebView: ', {
      reloadPage: window.RahmetApp?.reloadPage,
    });

    RahmetApp.reloadPage();
    return;
  }
  Analitycs.logEvent(EVENTS.OUTDATED_BUILD_NO_RELOAD);
}

function updateSite() {
  console.log('OutdatedBuildWatcherService updateSite');
  Analitycs.logEvent(EVENTS.OUTDATED_BUILD);
  document.location.reload();
}

export function refresh(key = 'refresh') {
  console.log('OutdatedBuildWatcherService refresh start', {
    RahmetApp: RahmetApp,
    isWebView: RahmetApp?.isWebView(),
  });
  let refreshCount = storage.parse(`refresh::${key}`, 0);
  console.log('refreshCount', refreshCount, key);

  if (refreshCount >= 2) {
    storage.set(`refresh::${key}`, 0);
    return;
  }
  storage.set(`refresh::${key}`, refreshCount + 1);

  if (RahmetApp.isWebView()) {
    console.log('OutdatedBuildWatcherService refresh page', {
      isWebView: RahmetApp?.isWebView(),
    });

    updateWebview();
  } else {
    updateSite();
  }
}

window.refresh = refresh;

async function checkHasNewRelease() {
  const RELEASE = import.meta.env.VITE_APP_RELEASE;
  if (DevConfigs.isDev) return false;
  console.log('OutdatedBuildWatcherServicecheckHasNewRelease', {
    release: import.meta.env.VITE_APP_RELEASE,
  });

  return fetch('/releases.json')
    .then(response => response.json())
    .then(data => {
      let hasRelease = !!data.releases.includes(RELEASE);
      console.log(
        'OutdatedBuildWatcherService checkHasNewRelease Stored Releases: ',
        {
          data,
          hasRelease,
        }
      );
      return !hasRelease;
    })
    .catch(() => true);
}

function refreshIfOutdated() {
  if (isOutdated()) refresh('outdatedBuildWatcher');
  checkHasNewRelease().then(res => {
    console.log('OutdatedBuildWatcherService checkHasNewRelease: ', {
      res,
    });

    Analitycs.logEvent('checkHasNewRelease', { res });
    if (res) {
      console.log('OutdatedBuildWatcherService res true: ', {
        res,
      });
      refresh('outdatedBuildWatcher');
    }
  });
}

function init() {
  console.log('OutdatedBuildWatcherService init: ', {
    VITE_APP_RELEASE_TIME: import.meta.env.VITE_APP_RELEASE_TIME,
    VITE_APP_RELEASE: import.meta.env.VITE_APP_RELEASE,
  });

  window.addEventListener('vite:preloadError', event => {
    throw new Error('vite:preloadError', { event });
  });

  refreshIfOutdated();
  // на сайте слушаем возврат на вкладку
  if (!RahmetApp.isWebView()) {
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        refreshIfOutdated();
      }
    });
  }

  eventBus.on('eb_on_reconnect_to_app', () => {
    console.log(
      'OutdatedBuildWatcherService eb_on_reconnect_to_app refreshIfOutdated EventBus'
    );
    refreshIfOutdated();
  });
}

export const OutdatedBuildWatcherService = {
  init,
};
