import {
  rFetchActivePromocodes,
  rFetchExpiredPromocodes,
  rStashPromocode,
} from '@shared/services/api/apis';
import { Logger } from '@shared/services/logger';
import { CART_EVENTS, CartEmitter } from '@services/cart/events';
import { authEmitter, AUTH_EVENTS } from '@shared/services/auth/events';
import { DevConfigs } from '@/utils/dev-config';
import { store } from '@/store';
import { PromocodeGiftService } from '@services/promocode-gift';

import adapter from './promocode.adapter';
import {
  saveUsedPromocode,
  getUsedPromocodes,
} from './hack-save-used-promocode';

function setLoader(state) {
  store.commit('promocodes/SET_IS_PRMOCODE_LOADING', state);
}

/**
 * Получает список активных промокодов
 * @returns {Promise}
 */
function fetchActive() {
  const used = getUsedPromocodes();
  return rFetchActivePromocodes({
    mock: DevConfigs.mockPromocodes,
  })
    .then(r => r.data)
    .then(v => {
      // @see ./hack-save-used-promocode.js:15
      return v.filter(vi => {
        return !used.includes(vi.promocode);
      });
    })
    .then(v => v.map(adapter));
}

/**
 * Получает список архивных промокодов
 * @returns {Promise}
 */
function fetchArchive() {
  return rFetchExpiredPromocodes({
    mock: DevConfigs.mockPromocodes,
  })
    .then(r => r.data.items)
    .then(v => v.map(adapter));
}

/**
 * Загрузить активные промокоды в стор.
 * Их нужно загружать при инициализации,
 * чтобы потом в профиле показать их количество
 * и в будущем показывать доступные в корзине
 * @returns {Promise<Array>}
 */
function loadActiveToStore() {
  return fetchActive().then(p => {
    store.commit('promocodes/SET_PROMOCODES', p);
  });
}

function setAppliedPromocode(p) {
  store.commit('promocodes/SET_APPLIED_PROMOCODE', p);
}

/**
 * Проверяет наличие применённого промокода из активных
 * @returns {boolean}
 */
function hasAppliedPromocode() {
  return !!store.state.promocodes.appliedPromocode;
}

/**
 * Возвращает текущий применённый промокод из активных
 * @returns {string}
 */
function getAppliedPromocode() {
  return store.state.promocodes.appliedPromocode;
}

/**
 * Сбросить применённый промокод
 */
function resetAppliedPromocodes() {
  store.commit('promocodes/RESET_APPLIED_PROMOCODE');
}

/**
 * Проверяет что есть промокоды
 * @returns {boolean}
 */
function hasPromocodes() {
  return !!store.state.promocodes.promocodes.length;
}

/**
 * Получить промокод, который истечёт в ближайшее время
 * @returns {string} Промокод
 */
function getClosestExpiring() {
  const arr = store.state.promocodes.promocodes;
  return arr[0]?.promocode || '';
}

/**
 * Если отсутствует применённый промокод —
 * применить следующий, который истечёт
 * в ближайшее время
 */
function applyClosestIfNo() {
  if (hasPromocodes() && !hasAppliedPromocode()) {
    setAppliedPromocode(getClosestExpiring());
  }
}

/**
 * Сохраняет на сервере промокод, полученный пользователем где-то
 * (например, от промоутера или в рекламе)
 * После сохранения будет отображаться
 * на странице со списком промокодов
 * @param {*} promocode
 * @returns
 */
function savePromocode(promocode) {
  return rStashPromocode({ promocode }, {});
}

function init() {
  authEmitter.on(AUTH_EVENTS.ON_AUTH, () => {
    loadActiveToStore().then(() => {
      applyClosestIfNo();
    });
  });
  CartEmitter.on(CART_EVENTS.ON_PAYMENT_DONE, event => {
    Logger.event('onPaymentDone', {
      ...event,
    });
    const { promocode } = event;
    // хак из-за бекенда
    saveUsedPromocode(promocode);
    // активный промокод больше не доступен
    resetAppliedPromocodes();
    PromocodeGiftService.resetGiftProduct();
    // обновляем активные промокоды после оплаты
    loadActiveToStore().then(() => {
      applyClosestIfNo();
    });
  });
}

export const PromocodesService = {
  setLoader,
  init,
  fetchActive,
  fetchArchive,
  loadActiveToStore,
  setAppliedPromocode,
  resetAppliedPromocodes,
  hasAppliedPromocode,
  getAppliedPromocode,
  hasPromocodes,
  getClosestExpiring,
  getUsedPromocodes,
  applyClosestIfNo,
  savePromocode,
};
