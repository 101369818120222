export const i18n = {
  messages: {
    ru: {
      title: 'Дарите друзьям 1500&nbsp;₸ —&nbsp;получайте 1500&nbsp;₸',
      do3orders: 'Совершите пять заказов и делитесь с друзьями своим промокодом.',
      friendGets: `
        Друг&nbsp;получит промокод на скидку в&nbsp;1500&nbsp;₸
        на&nbsp;первый заказ, а вы получите промокод
        на&nbsp;1500&nbsp;₸ после заказа друга.`,
      shareWithFriends: `
        Делитесь с друзьями своим промокодом. Друзья получат
        промокод на скидку в&nbsp;1500&nbsp;₸ на&nbsp;первый заказ, вы получите
        промокод на&nbsp;1500&nbsp;₸`,

      conditionsDetails: `Подробнее об условиях`,
      sharePromocode: 'Поделиться промокодом',
    },
    kk: {
      title: 'Досыңызға 1500&nbsp;₸ сыйлаңыз да, 1500&nbsp;₸ қайтып алыңыз.',
      do3orders: '5 тапсырыс жасаған соң достарыңызбен промокодпен бөлісіңіз.',
      friendGets: `
        Досыңыз алғашқы тапсырыс үшін 1500&nbsp;₸
        жеңілдік алады, ал сіз досыңыздың
        тапсырысы үшін 1500&nbsp;₸
        жеңілдік алатын боласыз.`,
      shareWithFriends: `
        Достарыңызға промокодты таратыңыз.
        Достарыңыз бірінші тапсырысы үшін 1500&nbsp;₸-лік жеңілдік промокоды,
        ал сізге 1500&nbsp;₸-лік промокод алатын боласыз`,

      conditionsDetails: `Шарттар туралы толығырақ білу`,
      sharePromocode: 'Промокодтпен бөлісу',
    },
  },
};
