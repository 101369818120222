<template>
  <transition name="slide">
    <div class="extended-delivery-plank">
      <IconExtendedDelivery class="extended-delivery-plank__icon" />
      <p class="extended-delivery-plank__msg">
        <!-- Расширенная зона доставки -->
        <span v-html="$t('extendedDeliveryZone')" />
      </p>
      <p class="extended-delivery-plank__descr">
        <!-- Доставка в этой зоне дольше: 30 – 40 минут -->
        <span v-html="$t('moreTime')" />
      </p>
    </div>
  </transition>
</template>

<script>
import IconExtendedDelivery from '@shared/components/icons/IconExtendedDelivery.vue';

export default {
  name: 'ExtendedDeliveryPlank',
  components: {
    IconExtendedDelivery,
  },
  i18n: {
    messages: {
      ru: {
        extendedDeliveryZone: `
          Расширенная зона доставки
        `,
        moreTime: `
          Доставка в&nbsp;этой зоне дольше: 30&nbsp;–&nbsp;40&nbsp;минут
        `,
      },
      kk: {
        extendedDeliveryZone: `
          Кеңейтілген жеткізу аймағы
        `,
        moreTime: `
          Бұл аймақта жеткізу ұзағырақ; 30 - 40 минут
        `,
      },
    },
  },
};
</script>

<style>
.extended-delivery-plank {
  min-height: 102px;
  background: #7284e4;
  color: #fff;
  padding-top: 12px;
  padding-left: 48px;
  padding-right: 16px;
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  top: -66px;
  box-shadow:
    0px 92px 92px rgba(0, 0, 0, 0.05),
    0px 3px 38.4354px rgba(0, 0, 0, 0.0334858),
    0px -3px 20.5494px rgba(0, 0, 0, 0.06),
    0px 5.79369px 11.5198px rgba(0, 0, 0, 0.0189792),
    0px 2.40368px 6.11809px rgba(0, 0, 0, 0.0132742),
    0px 0.705169px 2.54588px rgba(0, 0, 0, 0.00743532);
  border-radius: 24px 24px 0px 0px;
}

.extended-delivery-plank__icon {
  position: absolute;
  top: 12px;
  left: 16px;
}

.extended-delivery-plank__msg {
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.005em;
  font-weight: 900;
}

.extended-delivery-plank__descr {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.005em;
  padding-top: 4px;
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.4s;
}

.slide-enter,
.slide-leave-to {
  transform: translateY(100%);
  opacity: 0;
}
</style>
