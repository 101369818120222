import { api } from '@shared/services/api';
import { store } from '@/store';

// const CONTEST_END = new Date(Date.UTC(2025, 0, 14, 18, 0));

function loadDataToStore() {
  if (!store.getters['nyContest/nyEnabled']) return Promise.resolve({});
  if (!store.getters.isAuthorized) {
    return Promise.resolve({});
  }

  return api.lavka.fetchNYCompetitionMarathonRating().then(data => {
    const rating = data.data.rating || [];
    const userRank = data.data.userRank || {};

    store.commit(
      'nyContest/SET_WINNERS_LIST',
      rating.map(v => ({ place: v.rank, score: v.scores, phone: v.phone }))
    );
    store.commit('nyContest/SET_CURRENT_USER', {
      place: userRank.rank,
      score: userRank.scores,
    });
  });
}

// function endContest() {
//   store.commit('nyContest/SET_CONTEST_END', true);
// }

// let checkContestInterval = null;

// const checkIsContestEnded = () => {
//   const isContestEnded = CONTEST_END < new Date();

//   if (!isContestEnded && !store.getters['nyContest/contestEnded']) {
//     if (!checkContestInterval) {
//       checkContestInterval = setInterval(() => {
//         checkIsContestEnded();
//       }, 2000);
//     }
//   } else {
//     endContest();
//     if (checkContestInterval) {
//       clearInterval(checkContestInterval);
//     }
//   }
// };

export const NyContestService = {
  loadDataToStore,
};
