<template>
  <section id="MmQuestions" class="MmQuestions">
    <div class="MmQuestions__container">
      <MmSubtitle>Вопросы и ответы</MmSubtitle>
      <div class="MmQuestions__grid">
        <ul class="MmQuestions__list">
          <template v-if="$i18n.locale === 'ru'">
            <QItem class="MmQuestions__item">
              <template #question>Сколько это стоит?</template>
              <template #answer>
                Мы полностью берём на&nbsp;себя расходы за&nbsp;установку
                и&nbsp;обслуживание микромаркета. С&nbsp;вас&nbsp;— только
                предоставить помещение.
              </template>
            </QItem>
            <QItem class="MmQuestions__item">
              <template #question> Почему это выгодно для нас? </template>
              <template #answer>
                1. Напитки и&nbsp;готовая еда&nbsp;— всегда под&nbsp;рукой
                <br />
                2. Каждый день свежая еда
                <br />
                3. Легкая и&nbsp;удобная оплата
                <br />
                4. Доступная цена
                <br />
                5. Забота о&nbsp;сотрудниках и&nbsp;их&nbsp;потребностях
              </template>
            </QItem>
            <QItem class="MmQuestions__item">
              <template #question>
                Какие требования к&nbsp;установке?
              </template>
              <template #answer>
                1. Площадь 2-3&nbsp;м²
                <br />
                2. Прямой доступ к&nbsp;заземлённым розеткам (2&nbsp;шт.)
                <br />
                3. Более 100&nbsp;потенциальных покупателей
                <br />
                4. Доступ для&nbsp;нашего экспедитора, чтобы&nbsp;он&nbsp;мог
                беспрепятственно пополнять холодильник
              </template>
            </QItem>
            <QItem class="MmQuestions__item">
              <template #question>
                Какие способы оплаты поддерживает микромаркет?
              </template>
              <template #answer>
                В&nbsp;микромаркете можно оплатить через Kaspi, ApplePay,
                GooglePay, балансом с&nbsp;Kcell или Activ, а&nbsp;также
                привязать любую удобную карту.
              </template>
            </QItem>
            <QItem class="MmQuestions__item">
              <template #question>
                Как часто вы&nbsp;привозите продукты?
              </template>
              <template #answer>
                С&nbsp;понедельника по&nbsp;пятницу между 7:00 и&nbsp;10.30 наш
                экспедитор пополняет холодильник продуктами и&nbsp;свежей
                готовой едой.
              </template>
            </QItem>
          </template>
          <template v-if="$i18n.locale === 'kk'">
            <QItem class="MmQuestions__item">
              <template #question>Бағасы қанша?</template>
              <template #answer>
                Микромаркет орнату мен күтіп, ұстау шығындарын толықтай өз
                мойынымызға аламыз. Сіз тек орын берсеңіз жеткілікті.
              </template>
            </QItem>
            <QItem class="MmQuestions__item">
              <template #question>Бұл сіз үшін неліктен тиімді?</template>
              <template #answer>
                1. Сусындар мен тағамдар әрдайым қол созым жерде
                <br />
                2. Күн сайын жаңа піскен тағамдар
                <br />
                3. Жеңіл де ыңғайлы төлем
                <br />
                4. Қолжетімді баға
                <br />
                5. Қызметкерлеріңіз бен олардың қажеттілігіне деген қамқорлық
              </template>
            </QItem>
            <QItem class="MmQuestions__item">
              <template #question> Орнату талаптары қандай? </template>
              <template #answer>
                1. 2-3 м² алаң
                <br />
                2. Тікелей жерлестірілген розетка (2 д)
                <br />
                3. 100-ден астам ықтималды тұтынушы
                <br />
                4. Экспедиторымыз тоңазытқыштарды кедергісіз толтыруы үшін
                емін-еркін кіріп-шығуына рұхсат
              </template>
            </QItem>
            <QItem class="MmQuestions__item">
              <template #question>
                Микромаркетте төлемнің қандай тәсілдері бар?
              </template>
              <template #answer>
                Микромаркетте төлемді Kaspi, ApplePay, GooglePay арқылы, Kcell
                мен Activ теңгерімдерінің көмегімен, сондай-ақ кез келген
                картаны тіркеп жасауға болады
              </template>
            </QItem>
            <QItem class="MmQuestions__item">
              <template #question>
                Азық-түлікті қаншалықты жиі әкелесіздер?
              </template>
              <template #answer>
                Экспедиторымыз тоңазытқышты балғын өнімдер және дайын тағамдарға
                дүйсенбіден жұмаға дейін 7:00 мен 10.30 аралығында толтырады.
              </template>
            </QItem>
          </template>
        </ul>
        <WaQuestion class="MmQuestions__wa" />
      </div>
    </div>
  </section>
</template>

<script>
import MmSubtitle from '@/views/micromarket/components/MmSubtitle/MmSubtitle.vue';
import QItem from './QItem.vue';
import WaQuestion from './WaQuestion.vue';

export default {
  name: 'MmQuestions',
  components: {
    MmSubtitle,
    QItem,
    WaQuestion,
  },
};
</script>

<style scoped>
.MmQuestions {
  padding-top: 80px;
  width: calc(100% - 32px);
  max-width: 535px;
  margin: auto;
}

.MmQuestions__title {
  margin: 0;
}

.MmQuestions__container {
}

.MmQuestions__list {
  padding: 0;
  margin: 0;
  padding-top: 23px;
}

.MmQuestions__item + .MmQuestions__item {
  margin-top: 16px;
}

.MmQuestions__wa {
  margin-top: 16px;
}

@media (min-width: 768px) {
  .MmQuestions {
    padding-top: 193px;
    max-width: 988px;
  }

  .MmQuestions__title {
    font-size: 36px;
    font-weight: 700;
    line-height: 44px;
  }

  .MmQuestions__grid {
    display: grid;
    grid-template-columns: auto 288px;
    gap: 0 40px;
    align-items: flex-start;
    padding-top: 32px;
  }

  .MmQuestions__list {
    padding-top: 0;
  }

  .MmQuestions__wa {
    margin-top: 0;
  }

  .MmQuestions__item + .MmQuestions__item {
    margin-top: 20px;
  }
}
</style>
