import Vue from 'vue';
import Vuex from 'vuex';

import { DeliveryConfigService } from '@services/delivery-config';
import { DEFAULT_CITY } from '@services/city/constants';
import { AddressService } from '@services/address';
import { BannersService } from '@services/banners';
import { $log } from '@/utils/plugins/logger';
import { UserService } from '@services/user';
import { SaleCategoryService } from '@services/sale-category';
import { RahmetApp } from '@shared/RahmetApp';
import { ABTestService } from '@services/abtest';
import { PopularProductsService } from '@services/popular-products';

import { UserModule } from './modules/user';
import { CartModule } from './modules/cart';
import { ABTestModule } from './modules/abtest';
import { OrdersModule } from './modules/orders';
import { LoggerModule } from './modules/logger';
import { NyStatModule } from './modules/ny-stat';
import { AdultstModule } from './modules/adults';
import { BannersModule } from './modules/banners';
import { CatalogModule } from './modules/catalog';
import { DeliveryModule } from './modules/delivery';
import { eventBus } from '@/utils/plugins/event-bus';
import { ComponentModule } from './modules/component';
import { FavoritesModule } from './modules/favorites';
import { CashbacksModule } from './modules/cashbacks';
import { SamplingsModule } from './modules/samplings';
import { NyContestModule } from './modules/ny-contest';
import { ProductDBModule } from './modules/product-db';
import { PaidOrderModule } from './modules/paid-order';
import { PromocodesModule } from './modules/promocodes';
import { HeavyOrderModule } from './modules/heavy-order';
import { QuestionnaireModule } from './modules/questionnaire';
import { OrderProblemsModule } from './modules/order-problems';
import { ProductReviewModule } from './modules/product-review';
import { CategoryNpsModule } from '@/store/modules/category-nps';
import { ShoppingHistoryModule } from './modules/shopping-history';
import { PopularProductsModule } from './modules/popular-products';
import { LoyaltyModule } from './modules/loyalty';
import { CrossSellModule } from '@/store/modules/cross-sell';
import { SpecialCategoryModule } from '@/store/modules/special-category';

Vue.use(Vuex);

function createStoreOptions() {
  return {
    strict: true,
    modules: {
      shoppingHistory: ShoppingHistoryModule,
      orderProblems: OrderProblemsModule,
      productReview: ProductReviewModule,
      questionnaire: QuestionnaireModule,
      categoryNps: CategoryNpsModule,
      productDB: ProductDBModule,
      heavyOrder: HeavyOrderModule,
      promocodes: PromocodesModule,
      samplings: SamplingsModule,
      component: ComponentModule,
      favorites: FavoritesModule,
      paidOrder: PaidOrderModule,
      cashbacks: CashbacksModule,
      nyContest: NyContestModule,
      delivery: DeliveryModule,
      banners: BannersModule,
      catalog: CatalogModule,
      adults: AdultstModule,
      logger: LoggerModule,
      abtest: ABTestModule,
      orders: OrdersModule,
      nyStat: NyStatModule,
      user: UserModule,
      cart: CartModule,
      popularProducts: PopularProductsModule,
      loyalty: LoyaltyModule,
      crossSell: CrossSellModule,
      specialCategory: SpecialCategoryModule,
    },
    state: {
      isAuthorized: false,
      isInitializing: false,
      initPromise: null,
      isDesktop: false,
      isWebView: false,
      /** нужен для запуска общего лоудера апки при инициализаций апки при переходе через диплинк в protected роут */
      appLoader: false,
      appInitLoader: true,

      currentCityId: DEFAULT_CITY.id,
    },
    getters: {
      isAuthorized(state) {
        return state.isAuthorized;
      },
      isWebView(state) {
        return state.isWebView;
      },
      isDesktop(state) {
        return state.isDesktop;
      },
      appLoader(state) {
        return state.appLoader;
      },
      appInitLoader(state) {
        return state.appInitLoader;
      },
      currentCityId(state) {
        return state.currentCityId;
      },
      showNewMainPageAndCatalog() {
        return RahmetApp.isWebView();
      },
    },
    mutations: {
      SET_CURRENT_CITY_ID(state, value) {
        state.currentCityId = value;
      },
      SET_IS_WEB_VIEW(state, value) {
        state.isWebView = value;
      },
      SET_AUTH_STATE(state, value) {
        state.isAuthorized = value;
      },
      SET_INITIALIZING_STATE(state, { isInitializing, initPromise }) {
        state.isInitializing = isInitializing;
        state.initPromise = initPromise;
      },
      SET_IS_DESKTOP(state, value) {
        state.isDesktop = value;
      },
      SET_APP_LOADER(state, value) {
        state.appLoader = value;
      },
      SET_APP_INIT_LOADER(state, value) {
        state.appInitLoader = value;
      },
    },
    actions: {
      INIT_AUTHORIZED_FLOW(ctx, params) {
        /**
         * params.forceLoadNearestAddress
         *    если true, не запускать процесс поиска ближайшего адреса,
         *    оставить текущий загруженный. Нужно, чтобы процесс поиска
         *    запускался только при первичной загрузке, вроде)
         *    Нужно вспомнить зачем нужно, вроде были проблемы на странице
         *    заказа
         */
        let forceLoadNearestAddress = params?.forceLoadNearestAddress || false;
        $log('INIT_AUTHORIZED_FLOW', { ctx, params, forceLoadNearestAddress });

        const initPromise = UserService.loadUserProfile()
          .then(() => {
            return AddressService.initAddresses(forceLoadNearestAddress);
          })
          .then(() => ABTestService.init())
          .then(() => {
            return ctx.dispatch('delivery/FIND_WAREHOUSE');
          })
          .then(() => {
            return Promise.all([
              ctx.dispatch('cart/FETCH_CART'),
              BannersService.init(), // убрать отсюда для оптимизаций, они срабатывают в reconnectToApp дублированно
              SaleCategoryService.init(), // убрать отсюда для оптимизаций, они срабатывают в reconnectToApp дублированно
              PopularProductsService.init(),
            ]);
          });

        ctx.commit('SET_INITIALIZING_STATE', {
          isInitializing: true,
          initPromise,
        });

        return initPromise.finally(() => {
          $log('INIT_AUTHORIZED_FLOW finally');
          ctx.commit('SET_INITIALIZING_STATE', {
            isInitializing: false,
            initPromise: null,
          });
        });
      },
      INIT_DEVICE_ID_FLOW(ctx) {
        const initPromise = UserService.loadUnauthorizedProfile()
          .then(() => AddressService.initAddresses())
          .then(() => ABTestService.init())
          .then(() => BannersService.init()) // убрать отсюда для оптимизаций, они срабатывают в reconnectToApp дублированно
          .then(() => {
            return ctx.dispatch('delivery/FIND_WAREHOUSE');
          })
          .then(() => {
            return Promise.all([
              SaleCategoryService.init(), // убрать отсюда для оптимизаций, они срабатывают в reconnectToApp дублированно
              PopularProductsService.init(),
              ctx.dispatch('cart/FETCH_CART'),
            ]);
          });

        ctx.commit('SET_INITIALIZING_STATE', {
          isInitializing: true,
          initPromise,
        });

        return initPromise.finally(() => {
          ctx.commit('SET_INITIALIZING_STATE', {
            isInitializing: false,
            initPromise: null,
          });
        });
      },
      UPDATE_ORDER_CONTEXT(ctx) {
        return ctx
          .dispatch('delivery/FIND_WAREHOUSE')
          .catch(err => {
            ctx.commit('cart/RESET_CART');
            DeliveryConfigService.updateIsPaymentConfigShown();
            return Promise.reject(err);
          })
          .finally(() => {
            ctx.dispatch('cart/FETCH_CART').then(() => {
              eventBus.emit('eb_on_cart_fetched_after_address_changed');
            });
          });
      },
    },
  };
}

export const store = new Vuex.Store(createStoreOptions());
export { createStoreOptions };
