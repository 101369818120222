<template>
  <div class="w-full h-full relative overflow-hidden rounded-t-3xl">
    <UiLoader v-if="loading" class="w-full h-full absolute m-auto" />

    <MapLoadingFailure v-if="mapLoadState === 'failed'" />

    <MapControls
      :zoom="zoomBtns"
      :geo="geoLocationBtn"
      :geo-loading="geoLocationLoading"
      @onZoomIn="zoomIn"
      @onZoomOut="zoomOut"
      @onGeo="onClickDefineGeoLocation"
    />

    <YandexMap
      v-if="showMap"
      ref="mainMap"
      :controls="[]"
      :zoom="mapZoom"
      :coords="mapCenter"
      :settings="settings"
      class="h-full md:rounded-3xl overflow-hidden"
      @click="onMapClick"
      @map-was-initialized="onMapInit"
      @touchmove.native="onTouchStart"
    >
      <YmapMarker
        v-if="markerShown"
        :coords="coords"
        :icon="markerIcon"
        :marker-id="'marker'"
        :marker-type="'placemark'"
      />
      <!-- Для вебвью показываем дальние заказы -->
      <template v-if="extendedPolygons.length">
        <YmapMarker
          v-for="(polygon, index) in extendedPolygons"
          :key="`fpk_${index}`"
          :coords="polygon.coordinates"
          :marker-id="`fp_${index}`"
          :marker-type="'polygon'"
          :marker-fill="polygonSettings.extended.markerFill"
          :marker-stroke="polygonSettings.extended.markerStroke"
          @click="onMapClick"
        />
        <YmapMarker
          v-for="(polygon, index) in polygons"
          :key="index"
          :coords="polygon.coordinates"
          :marker-id="`p_${index}`"
          :marker-type="'polygon'"
          :marker-fill="
            polygon.type === 'regular'
              ? polygonSettings.regular.markerFill
              : polygonSettings.regularNearExtended.markerFill
          "
          :marker-stroke="
            polygon.type === 'regular'
              ? polygonSettings.regular.markerStroke
              : polygonSettings.regularNearExtended.markerStroke
          "
          @click="onMapClick"
        />
      </template>
      <template v-else>
        <YmapMarker
          v-for="(polygon, index) in polygons"
          :key="index"
          :coords="polygon.coordinates"
          :marker-id="`p_${index}`"
          :marker-type="'polygon'"
          :marker-fill="polygonSettings.regular.markerFill"
          :marker-stroke="polygonSettings.regular.markerStroke"
          @click="onMapClick"
        />
      </template>
    </YandexMap>
  </div>
</template>
<script>
import {
  MapService,
  MARKER_ICON,
  INITIAL_COORDS,
  YMAPS_SETTINGS,
  POLYGON_SETTINGS,
  EXTENDED_MARKER_ICON_RU,
  EXTENDED_MARKER_ICON_KAZ,
} from '@services/map';
import { CityService } from '@services/city';
import { yandexMap, ymapMarker } from 'vue-yandex-maps';

import MapLoadingFailure from '@pure-ui/components/Map/MapLoadingFailure/MapLoadingFailure.vue';
import MapControls from '@pure-ui/components/Map/MapControls/MapControls.vue';
import UiLoader from '@pure-ui/components/UiLoader/UiLoader.vue';

export default {
  name: 'RMap',
  components: {
    YmapMarker: ymapMarker,
    YandexMap: yandexMap,
    MapLoadingFailure,
    MapControls,
    UiLoader,
  },
  inject: ['toast'],
  props: {
    mapOffset: {
      type: Number,
      default: 0.001,
    },
    inputCoords: {
      type: Array,
      default: null,
    },
    geoLocationBtn: {
      type: Boolean,
      default: false,
    },
    zoomBtns: {
      type: Boolean,
      default: false,
    },
    isExtendedDelivery: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mapCenter: INITIAL_COORDS,
      coords: INITIAL_COORDS,

      geoLocationLoading: false,
      mapLoadState: 'loading',

      showMap: false,
      mapZoom: 15,

      markerShown: false,

      markerIcon: MARKER_ICON,
      settings: { ...YMAPS_SETTINGS, yandexMapDisablePoiInteractivity: true },

      polygons: [],
      polygonSettings: POLYGON_SETTINGS,

      extendedPolygons: [],

      extendedPlank: false,
      extendedPlankTimer: null,
    };
  },
  computed: {
    loading() {
      return this.mapLoadState === 'loading';
    },
  },
  watch: {
    inputCoords: {
      deep: true,
      handler(coords) {
        if (this.mapLoadState === 'loaded' && coords && coords.length === 2) {
          this.updateMapByCoords(coords);
        }
      },
    },
    isExtendedDelivery(newVal) {
      if (newVal) {
        this.markerIcon =
          this.$i18n.locale === 'ru'
            ? EXTENDED_MARKER_ICON_RU
            : EXTENDED_MARKER_ICON_KAZ;
      } else {
        this.markerIcon = MARKER_ICON;
      }
    },
  },
  mounted() {
    this.showMap = true;
    MapService.initMap().then(res => (this.mapLoadState = res));
  },
  methods: {
    onTouchStart() {
      this.$emit('actionBegin');
    },
    onTouchEnd() {
      this.$emit('onActionEnd');
    },
    onSelectLocation(collection) {
      this.$emit('onSelectLocation', collection);
    },
    hideMarker() {
      this.markerShown = false;
    },
    showMarker() {
      this.markerShown = true;
    },
    changeMapCenter(coords) {
      let offset = this.mapOffset || 0.006;
      try {
        const map = this.$refs.mainMap.myMap;
        const zoom = map.getZoom() - 15;
        offset /= 2 ** zoom;
      } catch (e) {
        console.log(e);
      }
      this.coords = coords;
      this.mapCenter = [this.coords[0] - offset, this.coords[1]];
    },
    onSuccess(coords, collection) {
      this.showMarker();
      this.changeMapCenter(coords);
      this.onSelectLocation(collection);
    },
    onError(err) {
      console.log('onError', err);
      this.toast.show(this.$t('errorHappened'), 'error');
      this.hideMarker();
      this.onSelectLocation(null);
    },
    updateMapByCoords(coords) {
      console.log('RMapV2 updateMapByCoords', { coords });
      return MapService.defineAddress(coords)
        .then(res => this.onSuccess(coords, res.collection))
        .catch(err => this.onError(err));
    },
    onMapClick(event) {
      this.hideMarker();
      const coords = event.get('coords');
      this.updateMapByCoords(coords).then(() => this.onTouchEnd());
    },
    // AccessFromOutside
    defineDefaults() {
      this.updateMapByCoords(CityService.getCityData().defaultCoords);
    },
    // AccessFromOutside
    async defineGeolocation(isInit = false) {
      this.$log('defineGeolocation');
      this.geoLocationLoading = true;
      MapService.defineGeoLocation()
        .then(res => this.onSuccess(res.coords, res.collection))
        .catch(err => {
          console.log('err', err);

          if (isInit) this.defineDefaults();
          else this.toast.show(this.$t('unableToGetGeoposition'), 'warning');
        })
        .finally(() => (this.geoLocationLoading = false));
    },
    onClickDefineGeoLocation() {
      this.onTouchStart();
      this.defineGeolocation();
    },
    onMapInit() {
      this.$emit('onMapInit');
      this.mapLoadState = 'loaded';
      MapService.fetchDeliveryZones().then(res => {
        this.polygons = res.regular;
        this.extendedPolygons = res.extended;
      });
    },
    zoomIn() {
      if (this.mapZoom === 20) return;
      this.mapZoom += 1;
    },
    zoomOut() {
      if (this.mapZoom === 2) return;
      this.mapZoom -= 1;
    },
  },
  i18n: {
    messages: {
      ru: {
        errorHappened: 'Произошла ошибка',
        unableToGetGeoposition:
          'Не удалось определить геопозицию, разрешите сбор геоданных в&nbsp;настройках браузера',
      },
      kk: {
        errorHappened: 'Қате орын алды',
        unableToGetGeoposition:
          'Тұрған жеріңіз табылмады, браузер параметріндегі геодеректерді жинауға рұхсат етіңіз',
      },
    },
  },
};
</script>
