<template>
  <RenderlessAddressContext
    v-slot="{ timeTitle, addressTitle, predictionTimeLoading, onAddressClick }"
    cart
  >
    <div
      class="CartAddressContext cart-address-context"
      @click="onAddressClick"
    >
      <div>
        <p class="cart-address-context-address">
          {{ addressTitle }}
        </p>
        <p
          class="cart-address-context-time"
          :class="{ 'skeleton-text': predictionTimeLoading }"
        >
          {{ timeTitle }}
        </p>
      </div>
      <div class="cart-address-context-arrow"><IconRightArrow /></div>
    </div>
  </RenderlessAddressContext>
</template>

<script>
import RenderlessAddressContext from './RenderlessAddressContext';
import IconRightArrow from './components/IconRightArrow.vue';
export default {
  components: { RenderlessAddressContext, IconRightArrow },
};
</script>

<style scoped>
.cart-address-context {
  @apply w-full flex items-center justify-between space-x-2;
}
.cart-address-context-address {
  @apply text-lg font-bold leading-6 line-clamp-1 break-all;
}
.cart-address-context-time {
  @apply mt-1 text-sirius-gray-1000 lowercase text-base font-medium leading-5;
}
.cart-address-context-arrow {
  @apply h-full;
}
.skeleton-text {
  background: linear-gradient(
    90deg,
    rgba(172, 170, 186, 0.3) 25%,
    rgba(172, 170, 186, 0.6) 50%,
    rgba(172, 170, 186, 0.3) 75%
  );
  background-size: 200% 100%;
  animation: skeleton-loading 1.5s infinite linear;
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
}
</style>
