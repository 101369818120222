import { isStreet } from './is-street';
import { isBuilding } from './is-building';
import { isDeliveryCity, parseDeliveryCity } from './is-delivery-city';
import { isDeliveryCountry } from './is-delivery-country';
import { fetchWarehouseZoneCoordinates } from './fetch-warehouse-zone-coordinates';
import { CityService } from '@services/city';
/**
 * Добавляет в DOM невидимую карту для yandex api
 */
function createHiddenMap() {
  if (document.getElementById('hiddenMap')) return;
  const map = document.createElement('div');
  map.className = 'hidden hidden w-full h-screen';
  map.id = 'hiddenMap';
  document.body.appendChild(map);
}

const insertValidationsToGeoObject = (geoObjects, initialCoordinates) => {
  const ymaps = window.ymaps;
  return new Promise(resolve => {
    fetchWarehouseZoneCoordinates().then(coords => {
      const regularPolygons = [];
      const extendedPolygons = [];
      createHiddenMap();

      const hiddenMap = new ymaps.Map('hiddenMap', {
        center: initialCoordinates,
        zoom: 7,
      });

      coords.regular.forEach(polygonCoord => {
        regularPolygons.push(new ymaps.geometry.Polygon(polygonCoord));
      });

      coords.far.forEach(polygonCoord => {
        extendedPolygons.push(new ymaps.geometry.Polygon(polygonCoord));
      });

      regularPolygons.forEach(elem => {
        elem.options.setParent(hiddenMap.options);
        elem.setMap(hiddenMap);
      });

      extendedPolygons.forEach(elem => {
        elem.options.setParent(hiddenMap.options);
        elem.setMap(hiddenMap);
      });

      // Мы тут и фильтруем, и в объекте поле редактируем, осторожно)
      const res = geoObjects.map(value => {
        const [lng, lat] = value.GeoObject.Point.pos.split(' ');

        let isInExtended = false;
        extendedPolygons.forEach(elem => {
          if (elem.contains([lat, lng])) {
            isInExtended = true;
          }
        });

        let isInTerritory = false;
        regularPolygons.forEach(elem => {
          if (elem.contains([lat, lng])) {
            isInTerritory = true;
          }
        });

        value.isInTerritory = isInTerritory;
        value.isInExtended = isInExtended;

        const { Address, kind } =
          value.GeoObject.metaDataProperty.GeocoderMetaData;

        value.isInCountry = isDeliveryCountry(Address);
        value.isInCity = isDeliveryCity(Address);
        value.cityName = parseDeliveryCity(Address)?.name || '';
        value.hasStreet = isStreet(kind);
        value.hasBuilding = isBuilding(kind);

        return value;
      });

      hiddenMap.destroy();
      resolve(res);
    });
  });
};

export const validateGeoObjectsCollection = (
  GeoObjectCollection,
  initialCoords
) => {
  const { featureMember } = GeoObjectCollection;

  let hasStreet = false;
  let hasBuilding = false;

  featureMember.forEach(member => {
    const { kind } = member.GeoObject.metaDataProperty.GeocoderMetaData;
    if (!hasStreet) hasStreet = isStreet(kind);
    if (!hasBuilding) hasBuilding = isBuilding(kind);
  });

  return insertValidationsToGeoObject(featureMember, initialCoords).then(
    res => {
      let addresses = res || [];
      let address = res[0] || null;

      return {
        addresses,
        address,
        hasStreet,
        hasBuilding,
      };
    }
  );
};

export const filterGeoObjectsByDelivery = GeoObjects => {
  return GeoObjects.filter(
    value => value.isInCountry && value.isInCity && value.hasBuilding
  );
};

export const sortGeoObjectsByDelivery = GeoObjects => {
  let currentCity = CityService.getCityData().name;
  return GeoObjects.sort(a => (a.cityName === currentCity ? -1 : 1));
};
