import { GeoService } from '@services/geo';
import { findNearestAddress, isAddressNear } from '../utils';

export async function getNearestAddress(addresses) {
  const userCoords = await GeoService.getUserLocation()
    .then(res => [res.latitude, res.longitude])
    .catch(() => null);

  if (!userCoords) {
    return {
      geo: false,
      nearestAddress: undefined,
      isNear: false,
    };
  }

  let isNear = false;
  const nearestAddress = findNearestAddress([...addresses], userCoords);
  if (nearestAddress) isNear = isAddressNear(nearestAddress, userCoords);

  return {
    geo: true,
    nearestAddress,
    isNear,
  };
}
