<template>
  <div v-if="promocodes.length" class="">
    <h2 class="font-bold texg-lg pt-4">
      {{ $t('promocodesActive') }}
    </h2>
    <div v-for="(p, index) in promocodes" :key="p.promocode" class="pt-4">
      <PromocodeCard
        v-bind="p"
        @onActivate="$emit('onActivate', $event)"
        @onConditionsClick="$emit('onConditionsClick', $event)"
      />
      <InviteFriends v-if="hasReferralProgram && index === 0" />
    </div>
  </div>
  <div v-else>
    <NoActivePromocodes />
    <InviteFriends v-if="hasReferralProgram" />
  </div>
</template>

<script>
import PromocodeCard from './PromocodeCard.vue';
import NoActivePromocodes from './NoActivePromocodes.vue';
import InviteFriends from './InviteFriends.vue';

export default {
  name: 'ActivePromocodes',
  components: {
    PromocodeCard,
    NoActivePromocodes,
    InviteFriends,
  },
  props: {
    promocodes: {
      type: Array,
      default: () => [],
    },
    hasReferralProgram: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({}),
  i18n: {
    messages: {
      ru: {
        promocodesActive: 'Активные промокоды',
      },
      kk: {
        promocodesActive: 'Белсенді промокодтар',
      },
    },
  },
};
</script>
