<template>
  <div class="HorizontalProductCardWrapper">
    <RenderlessProduct
      v-slot="{
        isPending,
        addToCart,
        quantity,
        removeFromCart,
        removeProductFromCart,
      }"
      :product="product"
      :feature="feature"
      :id-key="idKey"
    >
      <component
        :is="wrapperComp"
        @onRemove="removeFullProduct(removeProductFromCart)"
      >
        <HorizontalProductCard
          :name="productName"
          :img-src="productImage"
          :price="product.price"
          :old-price="hasDiscount ? product.old_price : 0"
          :quantity="quantity"
          :available="available"
          img-mix-blend
          :img-blur="checkIsImgBlured"
          :loyalty-percent="$store.getters['loyalty/available'] ? 1 : 0"
          @onClick="onClickProduct"
        >
          <template v-if="!available" #right>
            <div v-if="deleteBtn" class="w-6 h-6">
              <img
                :src="cancelIcon"
                alt="delete"
                @click="removeUnAvailableProduct(removeFromCart)"
              />
            </div>
          </template>
          <template v-else #right>
            <UiButton
              v-if="isAuthorized && isPending"
              size="sm"
              variant="gray-no-hover"
              style="width: 100px; max-width: 100px; height: 40px"
            >
              <UiLoader size="sm" variant="gray" />
            </UiButton>
            <UiButton
              v-else-if="quantity === 0"
              size="sm"
              class="HorizontalProductCardWrapper__toAdd"
              @click.stop="onIncrement(addToCart)"
            >
              {{ $t('toAdd') }}
            </UiButton>
            <ProductCounter
              v-else-if="quantity"
              class="HorizontalProductCardWrapper__counter"
              :model-value="quantity"
              :max="maxQuantity"
              size="sm"
              variant="secondary"
              :loading="isAuthorized ? isPending : null"
              @onIncrement="onIncrement(addToCart)"
              @onDecrement="onDecrement(removeFromCart)"
              @onMaxLimit="onMaxLimit"
            />
          </template>
        </HorizontalProductCard>
      </component>
    </RenderlessProduct>
  </div>
</template>

<script>
import { refresh } from '@services/outdated-build-watcher';
import { RenderlessProduct } from '@shared/components/product/RenderlessProduct';
import { onAdd, onDec, onClear, onFlushed } from '@services/product/analytics';
import { PRODUCT_ADDED } from '@shared/config/product';
import { ProductService } from '@services/product';
import { AdultsService } from '@services/adults';
import { RahmetApp } from '@shared/RahmetApp';
import { mapGetters } from 'vuex';

import HorizontalProductCard from '@pure-ui/components/Product/HorizontalProductCard/HorizontalProductCard.vue';
import UiLoader from '@pure-ui/components/UiLoader/UiLoader.vue';
import UiButton from '@pure-ui/components/UiButton/UiButton.vue';

export default {
  components: {
    UiLoader,
    UiButton,
    RenderlessProduct,
    HorizontalProductCard,
    ProductCounter: () =>
      import('@pure-ui/components/Product/ProductCounter/ProductCounter.vue'),
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    available: {
      type: Boolean,
      default: true,
    },
    addedFrom: {
      type: String,
      default: PRODUCT_ADDED.CART,
    },
    idKey: {
      type: String,
      default: 'product_id',
    },

    cart: Boolean,
    deleteBtn: Boolean,
  },
  data() {
    return {
      cancelIcon: new URL('@img/icons/cancel.svg', import.meta.url).href,
    };
  },
  computed: {
    ...mapGetters(['isAuthorized']),
    wrapperComp() {
      if (this.cart)
        return () => ({
          component: import(
            '@pure-ui/components/Product/CartProductCardWrapper/CartProductCardWrapper.vue'
          ).catch(err => {
            console.log('Err: ', err);
            refresh('CartProductCardWrapper');
          }),
        });

      return 'div';
    },
    checkIsImgBlured() {
      return (
        AdultsService.checkIsAdultProduct(this.product) &&
        !(
          AdultsService.checkIsAgreed() ||
          this.$store.getters['adults/isAgreed']
        )
      );
    },
    productImage() {
      if (!this.product) return '';

      if (this.product.path) return this.product.path;
      if (
        this.product.images &&
        this.product.images.length !== 0 &&
        this.product.images[0].path
      )
        return this.product.images[0].path;
      return '';
    },
    maxQuantity() {
      if (this.product.features) return this.feature.max_quantity || 0;
      return this.product.max_quantity || 0;
    },
    productName() {
      if (!this.feature?.name) {
        return this.product.name;
      }
      const arr = this.feature.name.split(' ');

      return `
        ${this.product.name}, ${Number(arr[0].replace(',', '.'))} ${arr[1]}`;
    },
    feature() {
      if (this.product.feature_id && this.product.feature_name) {
        return { id: this.product.feature_id, name: this.product.feature_name };
      }
      if (
        (this.product.feature_id || this.product.featureID) &&
        this.product.features
      ) {
        return this.product.features.find(e => this.product.featureID === e.id);
      }
      return {};
    },
    hasDiscount() {
      const oldPrice = this.product.old_price;
      return oldPrice > 0 && oldPrice !== this.product.price;
    },
  },
  methods: {
    onIncrement(addToCart) {
      RahmetApp.hapticSelection();
      onAdd(this.addedFrom, this.product, true);
      addToCart(this.addedFrom, this.product.category_id);
    },
    onDecrement(removeFromCart) {
      RahmetApp.hapticSelection();
      if (this.product.quantity === 1) onClear();
      onDec(this.addedFrom, this.product);
      removeFromCart();
    },
    removeFullProduct(removeProductFromCart) {
      onFlushed(this.product);
      removeProductFromCart();
    },
    removeUnAvailableProduct(removeFromCart) {
      removeFromCart(this.product.quantity);
    },
    goToFullProduct() {
      let query = ProductService.buildQueryOnGoToProduct(
        this.product,
        false,
        this.addedFrom
      );
      if (this.product.added_from) query.added_from = this.product.added_from;

      this.$emit('onNavigate');
      if (this.product.is_sample) return;
      if (this.$isDesktop) {
        this.$router.push({
          name: 'product-full',
          params: {
            id: `${this.product.product_id ?? this.product.id}`,
          },
          query,
        });
      } else {
        this.$eventBus.emit('eb_open_product_details_modal', {
          id: this.product.product_id,
          query,
        });
      }
    },
    onClickProduct(type) {
      console.log('onClickProduct', type);
      if (type === 'photo' || type === 'name') this.goToFullProduct();
    },
    onMaxLimit() {
      RahmetApp.hapticNotification('error');
    },
  },

  i18n: {
    messages: {
      ru: {
        toAdd: 'Добавить',
      },
      kk: {
        toAdd: 'Қосу',
      },
    },
  },
};
</script>

<style scoped>
.HorizontalProductCardWrapper__toAdd {
  @apply text-white font-medium w-full;
  width: 104px;
}
.HorizontalProductCardWrapper__counter {
  width: 100px;
  max-width: 100px;
}
</style>
