<template>
  <div class="MBlockSuccess flex pt-3 pb-4" v-on="$listeners">
    <UiInlineIcon name="promocode" />
    <div class="ml-3 mr-auto flex flex-col">
      <span>{{ promocode }}</span>
      <span
        class="MBlockSuccess__msg"
        :class="{ 'MBlockSuccess__msg--success': !giftProductOutOfStock }"
        v-html="
          giftProductOutOfStock ? $t('outOfStock') : $t('promocodeApplied')
        "
      />
    </div>
    <div v-if="!giftProduct" class="text-green-400 font-bold">
      {{ discount }} ₸
    </div>
    <UiInlineIcon
      class="MBlockSuccess__iconArr"
      name="arrow-right"
      style="color: #a4a2b7"
    />
  </div>
</template>

<script>
import UiInlineIcon from '@pure-ui/components/UiInlineIcon/UiInlineIcon.vue';

export default {
  name: 'MBlockSuccess',
  components: {
    UiInlineIcon,
  },
  props: {
    promocode: {
      type: String,
      default: '',
    },
    discount: {
      type: Number,
      default: -1,
    },
    giftProduct: {
      type: Boolean,
      default: false,
    },
    giftProductOutOfStock: {
      type: Boolean,
      default: false,
    },
  },
  i18n: {
    messages: {
      ru: {
        promocodeApplied: 'Промокод применён',
        outOfStock:
          'Промокод не&nbsp;применён, так как товара нет в&nbsp;наличии.',
      },
      kk: {
        promocodeApplied: 'Промокод қолданылды',
        outOfStock: 'Промокод қолданылмады, себебі тауар қолжетімді емес.',
      },
    },
  },
};
</script>

<style>
.MBlockSuccess__iconArr {
  margin-left: 6px;
  margin-right: -6px;
}

.MBlockSuccess__msg {
  @apply text-sirius-gray-900;
  font-size: 14px;
  line-height: 16px; /* 114.286% */
  letter-spacing: 0.07px;
}
.MBlockSuccess__msg.MBlockSuccess__msg--success {
  @apply text-sirius-green-200;
}
</style>
