import { api } from '@shared/services/api';
import { Logger as LOG } from '@shared/services/logger';
import { CART_EVENTS, CartEmitter } from '@services/cart/events';
import { DeliveryConfigService } from '@services/delivery-config';
import { rApplyProducts } from '@shared/services/api/apis';
import { SamplingsService } from '@services/samplings';
import { withAuth } from '../../decorators/with-auth';
import {
  initLocalCart,
  getLocalCart,
  setLocalCart,
  resetLocalCart,
} from '../../decorators/local-cart';
import { validateState, calculateTotalPrice } from './utils';
import {
  tempAddToCart,
  tempRemoveFromCart,
  tempFullRemoveFromCart,
} from './lib/temp-cart';

export const CartModule = {
  namespaced: true,
  state: {
    unpaidOrder: false,
    cart: {
      items: [],
      noItems: [],
    },
    appliances: null,
    promocode: '',
    comment: '',
  },
  getters: {
    cart(state) {
      return state.cart;
    },
    cartTotalPrice(state) {
      if (!state.cart.totalPrice) return 0;
      return state.cart.totalPrice;
    },
    cartTotalPriceWithDiscount(state) {
      if (!state.cart.totalPriceWithDiscount) return 0;
      return state.cart.totalPriceWithDiscount;
    },
    cartItemsSize(state) {
      return state.cart.items.reduce((total, item) => total + item.quantity, 0);
    },
    cartNoItemsSize(state) {
      return state.cart.noItems.reduce(
        (total, item) => total + item.quantity,
        0
      );
    },
    cartSize(state, getters) {
      return getters.cartItemsSize + getters.cartNoItemsSize;
    },
    hasUnpaidOrder(state) {
      return state.cart.items.length === 0 && state.unpaidOrder;
    },
    mappedCart(state) {
      return state.cart.items.reduce(
        (acc, item) =>
          Object.assign(acc, {
            [`${item.product_id}_${item.feature_id ? item.feature_id : 0}`]:
              item,
          }),
        {}
      );
    },
    isInCart(state, getters) {
      return id => getters.mappedCart.hasOwnProperty(`${id}_0`);
    },
    isInCartWithFeature(state, getters) {
      return (id, featureID) =>
        getters.mappedCart.hasOwnProperty(`${id}_${featureID}`);
    },
    getFromCart(state, getters) {
      return id => getters.mappedCart[id];
    },
    productIds(state) {
      return state.cart.items.map(v => v.product_id);
    },
    items(state) {
      return state.cart.items;
    },
    cartProducts(state) {
      return state.cart.items;
    },
    discount(state) {
      return state.cart.discount;
    },
    noItems(state) {
      return state.cart.noItems;
    },
    checkoutReminder(state, getters) {
      return getters.cartItemsSize > 0;
    },
    minAmountReminder(state, getters, rootState, rootGetters) {
      const { totalPrice } = state.cart;
      const minCheck = rootGetters['delivery/minCheck'];
      return minCheck > totalPrice ? minCheck - totalPrice : 0;
    },
    cartWeight(state) {
      const weight = state.cart.items.reduce(
        (sum, item) => sum + item.weight * item.quantity,
        0
      );
      // weight = +parseFloat(weight / 1000).toFixed(1);
      return weight;
    },
    isHeavyOrder(state, getters) {
      const { minAmountReminder, cartWeight, checkoutReminder } = getters;
      return checkoutReminder && !minAmountReminder && cartWeight >= 8000;
    },
    appliances(state) {
      const { appliances } = state;
      if (!appliances || !appliances.price || appliances.max_quantity <= 0)
        return null;

      return appliances;
    },
    promocode(state) {
      return state.promocode;
    },
    isFreeDeliveryPromocodeApplied(state) {
      return !!state.cart.cartPromocodeData?.freeDelivery;
    },
    comment(state) {
      return state.comment;
    },
  },
  mutations: {
    SET_UNPAID_STATE(state, value) {
      state.unpaidOrder = value;
    },
    UPDATE_CART(state, value) {
      const cart = {
        ...value,
        items: value.items.available || [],
        noItems: value.items.not_available || [],
      };
      state.cart = cart;
    },
    RESET_CART(state) {
      state.cart = { items: [], noItems: [] };
    },
    SET_UNAUTHORIZED_CART(state, value) {
      const cart = {
        ...value,
        noItems: [],
        totalPriceWithDiscount: value.totalPrice,
      };
      state.cart = cart;
    },
    SET_APPLIANCES(state, value) {
      state.appliances = value;
    },
    SET_PROMOCODE(state, value) {
      state.promocode = value;
    },
    SET_COMMENT(state, value) {
      state.comment = value;
    },
  },
  actions: {
    // нужен будет когда мы будем убирать лоудеры
    /**
     * Нужен для того чтоб временно изменить корзину (количество товаров)
     * для того что б урать лоудеры при операциях с корзиной,
     * потом респонс от сервера все равно отвалидиреует корзину
     * @param {*} ctx
     * @param {*} payload
     */
    TEMP_UPDATE_CART(ctx, payload) {
      let { methodType: type } = payload;
      let items = [...ctx.getters.cart.items];
      let noItems = [...ctx.getters.cart.noItems];
      let tempItems = [...items];

      if (type === 'add') tempItems = tempAddToCart(tempItems, payload);
      else if (type === 'remove')
        tempItems = tempRemoveFromCart(tempItems, payload);
      else if (type === 'fullRemove') {
        tempItems = tempFullRemoveFromCart(tempItems, payload);
      } else if (type === 'clearCart') {
        tempItems = [];
        noItems = [];
      } else if (type === 'removeUnavailable') {
        noItems = [];
      }

      let cart = {
        items: {
          available: tempItems || [],
          not_available: noItems || [],
        },
        totalPrice: calculateTotalPrice(tempItems),
        totalPriceWithDiscount: calculateTotalPrice(tempItems),
      };

      ctx.commit('UPDATE_CART', cart);
    },
    ADD_TO_CART: withAuth(
      (ctx, payload) => {
        let { productId, featureID } = payload;
        const res = validateState(ctx.rootState);
        let isNoAddress = res.invalid && res.err.message === 'NO_ADDRESS';
        if (res.invalid && !isNoAddress) {
          return Promise.reject(res.err);
        }
        const warehouseId = ctx.rootGetters['delivery/warehouseId'];

        const abGroups = ctx.rootGetters['abtest/testGroups'];
        // ctx.dispatch('TEMP_UPDATE_CART', { ...payload, methodType: 'add' });
        return ctx
          .dispatch('delivery/FIND_WAREHOUSE_IF_NEEDED', null, { root: true })
          .then(() => {
            return api.lavka.addToCart(
              productId,
              featureID,
              warehouseId,
              abGroups
            );
          })
          .then(data => {
            ctx.commit('UPDATE_CART', data.data);
            DeliveryConfigService.updateIsPaymentConfigShown();
            if (isNoAddress) return Promise.reject(res.err);
            return SamplingsService.loadSamplings(productId);
          })
          .catch(err => {
            return Promise.reject(err);
          });
      },
      (ctx, payload) => {
        const res = validateState(ctx.rootState);
        let isNoAddress = res.invalid && res.err.message === 'NO_ADDRESS';
        if (res.invalid && !isNoAddress) {
          return Promise.reject(res.err);
        }

        const { productId } = payload;

        const localCart = getLocalCart();
        let items = tempAddToCart(localCart, payload);

        setLocalCart(items);
        ctx.commit('SET_UNAUTHORIZED_CART', {
          items: items,
          totalPrice: calculateTotalPrice(items),
        });
        DeliveryConfigService.updateIsPaymentConfigShown();

        if (isNoAddress) return Promise.reject(res.err);
        return SamplingsService.loadSamplings(productId);
      }
    ),
    REMOVE_FROM_CART: withAuth(
      (ctx, payload) => {
        let { productId, featureID, quantity } = payload;

        const warehouseId = ctx.rootGetters['delivery/warehouseId'];
        const abGroups = ctx.rootGetters['abtest/testGroups'];
        // ctx.dispatch('TEMP_UPDATE_CART', { ...payload, methodType: 'remove' });
        return ctx
          .dispatch('delivery/FIND_WAREHOUSE_IF_NEEDED', null, { root: true })
          .then(() => {
            return api.lavka.removeFromCart(
              productId,
              featureID,
              warehouseId,
              quantity,
              abGroups
            );
          })
          .then(data => {
            ctx.commit('UPDATE_CART', data.data);
            DeliveryConfigService.updateIsPaymentConfigShown();
            return SamplingsService.loadSamplings();
          })
          .catch(err => {
            return Promise.reject(err);
          });
      },
      (ctx, { productId, featureID }) => {
        let localCart = [...getLocalCart()];
        let products = tempRemoveFromCart(localCart, {
          productId,
          featureID,
        });

        setLocalCart(products);

        ctx.commit('SET_UNAUTHORIZED_CART', {
          items: products,
          totalPrice: calculateTotalPrice(products),
        });
        DeliveryConfigService.updateIsPaymentConfigShown();

        return SamplingsService.loadSamplings();
      }
    ),
    REMOVE_PRODUCT_FROM_CART: withAuth(
      (ctx, payload) => {
        let { productId, featureID } = payload;
        const warehouseId = ctx.rootGetters['delivery/warehouseId'];
        const abGroups = ctx.rootGetters['abtest/testGroups'];

        // ctx.dispatch('TEMP_UPDATE_CART', {
        //   ...payload,
        //   methodType: 'fullRemove',
        // });
        return ctx
          .dispatch('delivery/FIND_WAREHOUSE_IF_NEEDED', null, { root: true })
          .then(() => {
            return api.lavka.removeFullProduct(
              productId,
              featureID,
              warehouseId,
              abGroups
            );
          })
          .then(data => {
            ctx.commit('UPDATE_CART', data.data);
            DeliveryConfigService.updateIsPaymentConfigShown();
            return SamplingsService.loadSamplings();
          });
      },
      (ctx, { productId, featureID }) => {
        const products = tempFullRemoveFromCart(getLocalCart(), {
          productId,
          featureID,
        });

        setLocalCart(products);

        ctx.commit('SET_UNAUTHORIZED_CART', {
          items: products,
          totalPrice: calculateTotalPrice(products),
        });
        DeliveryConfigService.updateIsPaymentConfigShown();

        return SamplingsService.loadSamplings();
      }
    ),
    CLEAR_CART: withAuth(
      ctx => {
        const warehouseId = ctx.rootGetters['delivery/warehouseId'];
        const abGroups = ctx.rootGetters['abtest/testGroups'];

        // ctx.dispatch('TEMP_UPDATE_CART', {
        //   methodType: 'clearCart',
        // });

        return ctx
          .dispatch('delivery/FIND_WAREHOUSE_IF_NEEDED', null, { root: true })
          .then(() => {
            return api.lavka.clearCart(warehouseId, abGroups);
          })
          .then(data => {
            ctx.commit('UPDATE_CART', data.data);
            DeliveryConfigService.updateIsPaymentConfigShown();
            SamplingsService.resetSamlpes();
          });
      },
      ctx => {
        resetLocalCart();
        ctx.commit('SET_UNAUTHORIZED_CART', {
          items: [],
          totalPrice: 0,
        });
        DeliveryConfigService.updateIsPaymentConfigShown();

        SamplingsService.resetSamlpes();
        return Promise.resolve();
      }
    ),
    REPLACE_INGREDIENT: withAuth(
      (ctx, payload) => {
        const warehouseId = ctx.rootGetters['delivery/warehouseId'];

        return ctx
          .dispatch('delivery/FIND_WAREHOUSE_IF_NEEDED', null, { root: true })
          .then(() => {
            // const { warehouse } = ctx.rootState.delivery;
            payload = Object.assign(payload, { warehouse_id: warehouseId });
            return api.lavka.replaceIngredient(payload);
          })
          .then(() => {
            ctx.dispatch('FETCH_CART');
            return SamplingsService.loadSamplings();
          });
      },
      () => {
        return SamplingsService.loadSamplings();
      }
    ),
    CLEAR_LOCAL_CART(ctx) {
      ctx.commit('UPDATE_CART', { items: [], noItems: [] });
      DeliveryConfigService.updateIsPaymentConfigShown();
      SamplingsService.resetSamlpes();
    },

    FETCH_CART_ON_AUTHORIZED(ctx) {
      return new Promise(resolve => {
        ctx.dispatch('FETCH_APPLIANCES');

        const warehouseId = ctx.rootGetters['delivery/warehouseId'];
        const abGroups = ctx.rootGetters['abtest/testGroups'];

        const localCart = getLocalCart();
        let localProducts = null;
        if (localCart && localCart.length !== 0) {
          localProducts = localCart.map(item => {
            return {
              product_id: +item.productId,
              feature_id: item.featureID || null,
              quantity: +item.quantity,
            };
          });
          resetLocalCart();
        }

        const applyLocalCart = () =>
          localProducts
            ? rApplyProducts(localProducts, warehouseId, abGroups)
            : api.lavka.fetchCart(warehouseId, abGroups);

        applyLocalCart()
          .then(({ data }) => {
            ctx.commit('UPDATE_CART', data);
            DeliveryConfigService.updateIsPaymentConfigShown();

            CartEmitter.emit(CART_EVENTS.ON_CART_FETCHED);
            return SamplingsService.loadSamplings();
          })
          .catch(() => {})
          .finally(() => {
            resolve();
          });
      });
    },
    FETCH_CART_ON_UNAUTHORIZED(ctx) {
      ctx.dispatch('FETCH_APPLIANCES');

      const localCart = getLocalCart();

      LOG.event('FETCH_CART_ON_UNAUTHORIZED', { localCart });

      ctx.commit('SET_UNAUTHORIZED_CART', {
        items: localCart,
        totalPrice: calculateTotalPrice(localCart),
      });
      DeliveryConfigService.updateIsPaymentConfigShown();

      return SamplingsService.loadSamplings();
    },
    FETCH_CART(ctx) {
      const { isAuthorized } = ctx.rootState;
      if (isAuthorized) return ctx.dispatch('FETCH_CART_ON_AUTHORIZED');
      return ctx.dispatch('FETCH_CART_ON_UNAUTHORIZED');
    },
    REMOVE_UNAVAILABLE: withAuth(
      ctx => {
        const warehouseId = ctx.rootGetters['delivery/warehouseId'];
        const abGroups = ctx.rootGetters['abtest/testGroups'];

        // ctx.dispatch('TEMP_UPDATE_CART', {
        //   methodType: 'removeUnavailable',
        // });

        return ctx
          .dispatch('delivery/FIND_WAREHOUSE_IF_NEEDED', null, { root: true })
          .then(() => {
            return api.lavka.removeUnavailable(warehouseId, abGroups);
          })
          .then(({ data }) => {
            ctx.commit('UPDATE_CART', data);
            DeliveryConfigService.updateIsPaymentConfigShown();
          })
          .catch(() => {});
      },
      () => {
        return Promise.resolve();
      }
    ),
    FETCH_APPLIANCES(ctx) {
      api.lavka
        .fetchAppliances(ctx.rootGetters['delivery/warehouseId'])
        .then(({ data }) => {
          ctx.commit('SET_APPLIANCES', data);
        })
        .catch(() => {});
    },
    SET_UNPAID_ORDER(ctx, state) {
      ctx.commit('SET_UNPAID_STATE', state);
    },
    INIT_LOCAL_CART() {
      initLocalCart();
    },
    VALIDATE_STATE(ctx) {
      const res = validateState(ctx.rootState);
      if (res.invalid) {
        return Promise.reject(res.err);
      }
      return Promise.resolve();
    },
  },
};
