<template>
  <transition name="fade" appear>
    <div class="map-failure">
      <UiInlineIcon name="map-crestik" class="map-failure-icon" />

      <p class="map-failure-text">
        {{ $t('unableToLoadMap') }}
        <br />
        {{ $t('checkInternetConnection') }}
      </p>
    </div>
  </transition>
</template>

<script>
import UiInlineIcon from '@pure-ui/components/UiInlineIcon/UiInlineIcon.vue';

export default {
  name: 'MapLoadingFailure',
  components: { UiInlineIcon },
  i18n: {
    messages: {
      ru: {
        unableToLoadMap: 'Не удалось загрузить карту.',
        checkInternetConnection: 'Проверьте интернет соединение',
      },
      kk: {
        unableToLoadMap: 'Картаны жүктеу мүмкін болмады',
        checkInternetConnection: 'Интернетіңізді тексеріңіз',
      },
    },
  },
};
</script>

<style scoped>
.map-failure {
  @apply h-full flex flex-col items-center justify-center 
    leading-tight text-sm text-gray-700 ease-linear duration-500;
}
.map-failure-icon {
  @apply w-10 h-10 p-1 
    flex items-center justify-center 
    text-white bg-gray-700 box-content rounded-full;
}
.map-failure-text {
  @apply mt-4 text-center;
}
</style>
