export const PromocodesModule = {
  namespaced: true,
  state: {
    /**
     * Активные промокоды, которые можно применить в корзине.
     * Промокод, у которого ближе всех время истечения,
     * должен автоматически примениться в корзине
     */
    promocodes: [],
    /**
     * Промокод, который будет применён автоматически
     * при переходе в корзину.
     * Здесь же хранится любой другой введённый юзером
     * промокод, не обязательно из уникальных
     */
    appliedPromocode: '',
    isPromocodeLoading: false,
    /* промокоды на товар */
    promocodeGiftProduct: null,
    alreadySeenGiftProduct: {
      id: null,
      promocodeId: null,
      hasQuantity: false,
    },
  },
  getters: {
    count: state => state.promocodes.length,
    appliedPromocode: state => state.appliedPromocode,
    isPromocodeLoading: state => state.isPromocodeLoading,
    promocodeGiftProduct: state => state.promocodeGiftProduct,
    alreadySeenGiftProduct: state => state.alreadySeenGiftProduct,
    giftProductOutOfStock: state =>
      state.promocodeGiftProduct?.max_quantity === 0,
    availablePromocodeGiftProduct: state =>
      state.promocodeGiftProduct && state.promocodeGiftProduct?.max_quantity > 0
        ? state.promocodeGiftProduct
        : null,
  },
  mutations: {
    SET_PROMOCODES(state, value) {
      state.promocodes = value;
    },
    RESET_APPLIED_PROMOCODE(state) {
      state.appliedPromocode = '';
    },
    SET_APPLIED_PROMOCODE(state, promocode) {
      state.appliedPromocode = promocode;
    },
    SET_IS_PRMOCODE_LOADING(state, value) {
      state.isPromocodeLoading = value;
    },
    SET_PROMOCODE_GIFT_PRODUCT(state, value) {
      state.promocodeGiftProduct = value;
    },
    SET_ALREADY_SEEN_GIFT_PRODUCT(state, value) {
      state.alreadySeenGiftProduct = { ...value };
    },
  },
};
