<template>
  <button
    class="ui-button-new cursor-pointer"
    :class="`
      ${sizes[size]}
      ${variants[variant]}
      ${size}
      ${useDefaultPadding ? sizes_padding[size] : ''}
      ${round ? rounds[round] : ''}
      ${height ? heights[height] : ''}
      `"
    v-on="$listeners"
  >
    <slot :class="`${size == 'lg' ? 'px-0.5' : ''}`"></slot>
  </button>
</template>

<script>
const SIZES = {
  none: '',
  xs: '--xs',
  sm: '--sm',
  md: '--md',
  lg: '--lg',
  xl: '--xl',
};

const SIZES_PADDING = {
  none: '',
  xs: '',
  sm: 'px-0 py-1',
  md: 'px-4 py-2',
  lg: 'px-4 py-4',
  xl: 'px-6 py-4',
};

const ROUNDS = {
  '': '',
  none: 'rounded-none',
  sm: 'rounded-sm',
  md: 'rounded-md',
  lg: 'rounded-lg',
  xl: 'rounded-xl',
  '2xl': 'rounded-2xl',
  '3xl': 'rounded-3xl',
};

const HEIGHTS = {
  '': '',
  lg: 'h-13',
  xl: 'h-16',
};

const VARIANTS = {
  primary: '--primary',
  gray: '--gray',
  white: '--white',
  darkGray: '--dark-gray',
  'light-gray': '--light-gray',
};

export default {
  name: 'UiButton',
  props: {
    size: {
      type: String,
      default: 'md',
      validator: v => SIZES.hasOwnProperty(v),
    },
    variant: {
      type: String,
      default: 'primary',
      validator: v => VARIANTS.hasOwnProperty(v),
    },
    round: {
      type: String,
      default: '',
      validator: v => ROUNDS.hasOwnProperty(v),
    },
    height: {
      type: String,
      default: '',
      validator: v => HEIGHTS.hasOwnProperty(v),
    },
    useDefaultPadding: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      sizes: SIZES,
      variants: VARIANTS,
      sizes_padding: SIZES_PADDING,
      rounds: ROUNDS,
      heights: HEIGHTS,
    };
  },
};
</script>

<style lang="postcss">
.ui-button-new {
  @apply inline-flex
  items-center
  justify-center
  transition ease-in duration-75;

  &.--xs {
    @apply rounded-2xl;
  }

  &.--sm {
    height: 36px;
    @apply rounded-xl;
  }

  &.--md {
    height: 44px;
    @apply rounded-xl;
  }

  &.--lg {
    @apply rounded-xl h-13;
  }

  &.--xl {
    @apply h-16 rounded-2xl;
  }

  &.--full {
    @apply rounded-full;
  }

  &:focus {
    @apply outline-none;
  }

  &.--primary {
    @apply bg-sirius-orange-100 md:hover:bg-sirius-orange-400 text-white;

    /*
    &:hover {
      @apply bg-sirius-orange-400;
    }
    */

    &:active {
      @apply bg-sirius-orange-300;
    }

    &:disabled {
      @apply opacity-50;
    }
  }

  &.--gray {
    @apply bg-sirius-gray-200 text-sirius-black-100 md:hover:bg-sirius-gray-300;

    /* &:hover {
      @apply bg-sirius-gray-300;
    }
    */

    &:active {
      @apply bg-sirius-gray-100;
    }

    &:disabled {
      @apply opacity-50;
    }
  }
  &.--dark-gray {
    @apply bg-sirius-gray-300 text-sirius-black-100;

    &:active {
      @apply bg-sirius-gray-200;
    }

    &:disabled {
      @apply opacity-50;
    }
  }

  &.--white {
    @apply bg-white text-sirius-black-100;

    &:active {
      @apply bg-white;
    }

    &:disabled {
      @apply opacity-50;
    }
  }

  &.--light-gray {
    @apply text-sirius-black-100;
    background: rgba(212, 211, 223, 0.15);

    &:active {
      @apply bg-white;
    }

    &:disabled {
      @apply opacity-50;
    }
  }

  &.--transparent {
    @apply bg-transparent;
  }
}

@meida touche;
</style>
