<template>
  <div class="TopMenu">
    <div class="TopMenu__scrollable">
      <div class="TopMenu__itemsWrapper">
        <button
          v-for="(button, index) in buttons"
          :key="index"
          class="TopMenu__item"
          @click="$emit('onScrollTo', button.event)"
        >
          {{ $t(button.text) }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';

defineEmits(['onScrollTo']);
const props = defineProps({
  score: {
    type: Number,
    default: 0,
  },
  showScore: Boolean,
});
const buttons = computed(() => {
  const bts = [
    {
      text: 'myResults',
      event: 'ny-results',
    },
    {
      text: 'prizes',
      event: 'ny-prizes',
    },
    {
      text: 'scoreTable',
      event: 'ny-table',
    },
    {
      text: 'rules',
      event: 'ny-rules',
    },
  ];
  if (!props.score) bts.splice(0, 1);
  if (props.showScore) return bts;
  return bts.filter(b => !['ny-results', 'ny-table'].includes(b.event));
});
</script>

<script>
export default {
  i18n: {
    messages: {
      ru: {
        myResults: 'Мои результаты 🥰',
        conditions: 'Условия 🖊',
        prizes: 'Призы 🎁',
        scoreTable: 'Таблица баллов 🔍',
        rules: 'Правила 📌',
      },
      kk: {
        myResults: 'Менің нәтижелерім 🥰',
        conditions: 'Шарттар 🖊',
        prizes: 'Сыйлықтар 🎁',
        scoreTable: 'Ұпай кестесі 🔍',
        rules: 'Ережелер 📌',
      },
    },
  },
};
</script>

<style scoped>
.TopMenu {
  display: flex;
  justify-content: center;
}
.TopMenu__itemsWrapper {
  border-right: 16px solid transparent;
  border-left: 16px solid transparent;
  display: inline-flex;
  gap: 8px;
}
.TopMenu__scrollable {
  overflow-x: auto;
}
.TopMenu__scrollable::-webkit-scrollbar {
  display: none;
}
.TopMenu__item {
  padding: 8px 16px;
  border-radius: 12px;
  white-space: nowrap;
  background: #ffffff;
}
.TopMenu__item:focus {
  outline: none;
}

@media screen and (min-width: 768px) {
  .TopMenu__scrollable {
    text-align: center;
    gap: 16px;
  }

  .TopMenu__item {
    padding: 12px 24px;
    font-size: 24px;
    line-height: 30px;
    font-weight: 500;
  }
}
</style>
