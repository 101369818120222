<template>
  <div v-if="promocodes.length" class="archive">
    <template v-if="!opened">
      <div class="text-center">
        <button
          class="ArchivePromocodesLink font-medium text-sirius-orange-100"
          @click="opened = true"
        >
          {{ $t('promocodesArchive') }}
          <!-- prettier-ignore -->
          <svg class="inline-block" style="margin-bottom: 1px; margin-left: 1px;" width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.5 6.16675L8.5 11.1667L3.5 6.16675" stroke="#FFA100" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </button>
      </div>
    </template>
    <template v-else>
      <h2 class="font-bold texg-lg pt-2">
        {{ $t('promocodesArchive') }}
      </h2>
      <div v-for="p in promocodes" :key="p.promocode" class="pt-4">
        <PromocodeCard v-bind="p" />
      </div>
    </template>
  </div>
</template>

<script>
import PromocodeCard from './PromocodeCard.vue';

export default {
  name: 'ArchivePromocodes',
  components: {
    PromocodeCard,
  },
  props: {
    promocodes: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    opened: false,
  }),
  i18n: {
    messages: {
      ru: {
        promocodesArchive: 'Архивные промокоды',
      },
      kk: {
        promocodesArchive: 'Архивтелген промокод',
      },
    },
  },
};
</script>

<style scoped>
.ArchivePromocodesLink {
  position: relative;
  padding-left: 20px;
}

.ArchivePromocodesLink::before {
  content: '';
  display: block;
  position: absolute;
  left: -40px;
  right: -40px;
  top: -20px;
  bottom: -20px;
}
</style>
