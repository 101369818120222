import { isDeliveryCity } from './is-delivery-city';

export const defineGeolocation = ymaps =>
  new Promise((resolve, reject) =>
    ymaps.geolocation
      .get({ mapStateAutoApply: true, provider: 'browser', useMapMargin: true })
      .then(res => resolve(res.geoObjects.position))
      .catch(reject)
  );

export const geocodeSinglePosition = (ymaps, coords) =>
  new Promise((resolve, reject) =>
    ymaps
      .geocode(coords, { results: 1, json: true })
      .then(res => resolve(res.GeoObjectCollection))
      .catch(reject)
  );

export const isStreetInCity = geoObjects => {
  return geoObjects.some(value => {
    const { Address, kind } = value.GeoObject.metaDataProperty.GeocoderMetaData;

    return (
      Address.country_code === 'KZ' &&
      (kind === 'street' || kind === 'district' || kind === 'house') &&
      isDeliveryCity(Address)
    );
  });
};

export const isBuildingInCity = geoObjects => {
  return geoObjects.some(value => {
    const { Address, kind } = value.GeoObject.metaDataProperty.GeocoderMetaData;

    return (
      Address.country_code === 'KZ' &&
      kind === 'house' &&
      isDeliveryCity(Address)
    );
  });
};
