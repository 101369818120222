<template>
  <div
    class="UiTextArea ui-textarea"
    :class="`--${size} ${value || focused ? '--focused' : ''}`"
  >
    <label
      :for="`${$attrs.name}_id`"
      :class="`ui-textarea-label ${borderColor} `"
    >
      <div v-if="label" class="ui-textarea-title">
        {{ label }}
      </div>
      <textarea
        :id="`${$attrs.name}_id`"
        v-auto-focus="{ disabled: !autofocus }"
        :value="value"
        class="ui-textarea-input"
        :class="{ 'no-resize': noResize }"
        v-bind="$attrs"
        v-on="$listeners"
        @focus="onFocus"
        @blur="onBlur"
        @input="onInput"
      ></textarea>
    </label>

    <div v-if="invalid && errorMessage.length > 0" class="ui-textarea-message">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import AutoFocus from '@/utils/directives/autofocus';

export default {
  name: 'UiTextarea',
  directives: {
    AutoFocus,
  },
  model: {
    prop: 'value',
    event: 'onInput',
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: '',
    },
    noResize: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'md',
    },

    autofocus: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    focused: false,
  }),
  computed: {
    borderColor() {
      if (this.invalid) return '--state-invalid';
      if (this.focused) return '--state-focused';
      return '--state-default';
    },
  },
  methods: {
    onInput(event) {
      this.$emit('onInput', event.target.value);
    },
    onFocus() {
      this.focused = true;
      this.$emit('onFocus');
    },
    onBlur() {
      this.focused = false;
      this.$emit('onBlur');
    },
  },
};
</script>

<style scoped>
/* .ui-textarea {
  @apply ;
} */
.ui-textarea-label {
  @apply pl-3 pr-2 w-full block
    rounded-xl border
    transition-colors duration-200 ease-linear
  bg-sirius-gray-200;
  padding-top: 11px;
  padding-bottom: 11px;
}
.ui-textarea-title {
  @apply absolute
    duration-200 ease-linear
    text-sirius-gray-100;
  transition-property: font-size, line-height, transform;
}
.--focused .ui-textarea-title {
  @apply -translate-y-2 text-xs leading-4;
}
.ui-textarea-input {
  @apply p-0 w-full
    appearance-none focus:outline-none
    bg-transparent
    font-normal text-sirius-black-100;
  margin-top: 7px;
}
.no-resize {
  resize: none;
}
.ui-textarea-message {
  @apply mt-0.5 pl-2 flex
   text-sirius-red-200 text-xs leading-tight;
}
.--md .ui-textarea-label {
  @apply font-medium leading-5;
}
.--M .ui-textarea-label {
  @apply text-base font-normal leading-5;
}
.--M .ui-textarea-label {
  @apply text-base font-normal leading-5;
}
.--M .ui-textarea-input {
  @apply font-normal;
}
.--M .ui-textarea-message {
  @apply pl-0;
}

.--state-invalid {
  @apply border-sirius-red-200;
}
.--state-focused {
  @apply border-sirius-orange-100;
}
.--state-default {
  @apply border-sirius-gray-200;
}
</style>
