<template>
  <div class="TopHeroDesktop">
    <WhiteBreadcrumbs class="TopHeroDesktop__breadcrumbs" />
    <img
      v-if="$i18n.locale === 'ru'"
      src="./images/decor-ru.png"
      class="TopHeroDesktop__decor"
      width="1252"
    />
    <img
      v-if="$i18n.locale === 'kk'"
      src="./images/decor-kk.png"
      class="TopHeroDesktop__decor"
      width="1252"
    />
    <div class="TopHeroDesktop__container">
      <h1 class="TopHeroDesktop__title">
        <span v-html="xmasFatherNear" />
      </h1>
      <div class="TopHeroDesktop__descr">
        <span v-html="descr" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import WhiteBreadcrumbs from '@/views/ny-2024-contest/components/TopHero/components/WhiteBreadcrumbs/WhiteBreadcrumbs.vue';

export default {
  name: 'TopHeroDesktop',
  components: {
    WhiteBreadcrumbs,
  },
  computed: {
    ...mapGetters('nyContest', ['contestEnded']),
    xmasFatherNear() {
      return this.contestEnded
        ? this.$t('xmasFatherNearResults')
        : this.$t('xmasFatherNear');
    },
    descr() {
      return this.contestEnded
        ? this.$t('descrResults')
        : this.$t('descr');
    },
  },
  i18n: {
    messages: {
      ru: {
        xmasFatherNear: 'Дед&nbsp;Мороз Рядом',
        xmasFatherNearResults: 'Результаты Розыгрыша',
        descr: 'Cовершайте покупки от 3000₸  и выигрывайте призы',
        descrResults: 'Розыгрыш завершился 14 января в 23:00',
      },
      kk: {
        xmasFatherNear: 'Рядом&nbsp;Аяз Атасы',
        xmasFatherNearResults: 'Ұтыс ойынының нәтижелері',
        descr: '3000 ₸ асатын тапсырыстар үшін ұпай алыңыз',
        descrResults: 'Ұтыс ойыны 14 қаңтарда сағат 23:00-де аяқталды.',
        finished: 'Ұтыс аяқталды',
      },
    },
  },
};
</script>

<style scoped>
.TopHeroDesktop {
  position: relative;
  max-width: 1188px;
  margin: auto;
  height: 590px;
  background: url('./images/background.png') center top / 100% no-repeat;
  margin-top: 50px;
}

.TopHeroDesktop__breadcrumbs {
  position: absolute;
  z-index: 1;
  top: 56px;
  left: 494px;
}

.TopHeroDesktop__decor {
  position: absolute;
  left: 56px;
  top: 423px;
  max-width: 95%;
}

.TopHeroDesktop__container {
  padding-top: 136px;
}

.TopHeroDesktop__title {
  color: #fff;
  text-align: center;
  font-size: 85px;
  font-weight: 900;
  line-height: 76px;
  letter-spacing: -5.95px;
  max-width: 440px;
  margin: auto;
}

.TopHeroDesktop__descr {
  color: var(--text-text-invert, #fff);
  text-align: center;
  text-shadow: 0px 2px 16px rgba(255, 54, 85, 0.24);
  font-size: 24px;
  font-weight: bold;
  line-height: 30px;
  max-width: 488px;
  margin: auto;
  padding-top: 24px;
}
</style>
