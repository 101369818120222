/**
 * @typedef ClosureReason
 * @prop {number} id
 * @prop {number[]|null} polygon_ids
 * @prop {string} title_kk
 * @prop {string} title_ru
 * @prop {string} description_kk
 * @prop {string} description_ru
 * @prop {string} reminder_title_kk
 * @prop {string} reminder_title_ru
 *
 * @param {*} closureReason
 * @returns {RClosureReason | null}
 */
export function closureReasonAdapter(closureReason) {
  if (!closureReason) return null;
  const reason = {
    id: closureReason.id,
    polygon_ids: closureReason.polygon_ids || [],
    kk: {
      title: closureReason.title_kk || '',
      description: closureReason.description_kk || '',
      reminder: closureReason.reminder_title_kk || '',
    },
    ru: {
      title: closureReason.title_ru || '',
      description: closureReason.description_ru || '',
      reminder: closureReason.reminder_title_ru || '',
    },
  };

  return reason;
}
