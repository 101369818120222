import { client } from '../../clients/client';

export function rGetSuggestions(payload, isAuth = false) {
  let publicUrl = '';
  if (!isAuth) publicUrl = '/public';
  return client
    .get(`lavka-favorites/v1${publicUrl}/cross-sell/suggestions`, {
      params: payload,
    })
    .then(response => response.data);
}
