<template>
  <ToolBar :back="onBackClick">
    {{ $t('myPromocodes') }}
  </ToolBar>
</template>

<script>
import ToolBar from '@shared/components/layout/ToolBar.vue';

export default {
  name: 'PromocodesView',
  components: {
    ToolBar,
  },
  props: {
    fromPushEvent: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onBackClick(router) {
      if (window.history.length < 3 || this.fromPushEvent) {
        return router.push({ name: 'main' });
      }
      return router.go(-1);
    },
  },
  i18n: {
    messages: {
      ru: {
        myPromocodes: 'Мои промокоды',
      },
      kk: {
        myPromocodes: 'Менің промокодтарым',
      },
    },
  },
};
</script>
