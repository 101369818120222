<template>
  <div class="ResultsBlock">
    <h3 style="color: #135141">{{ $t('title') }}</h3>
    <p v-html="$t('bonus_stopping')"></p>
    <p v-html="$t('announcement')"></p>
    <p v-html="$t('random')"></p>
    <p style="color: #009164" v-html="$t('disclaimer')"></p>
  </div>
</template>
<script>
export default {
  name: 'ResultsBlock',
  i18n: {
    messages: {
      ru: {
        title: 'Результаты',
        bonus_stopping:
          'Счетчик заказов будет приостановлен 14&nbsp;января в&nbsp;23:00',
        announcement:
          '15 января 2025&nbsp;года будут определены ТОП-50&nbsp;участников, результаты опубликуют в&nbsp;приложении',
        random:
          'С помощью рандомайзера будет выбран победитель Apple&nbsp;iPhone&nbsp;16 среди всех пользователей, набравших&nbsp;50 и&nbsp;более баллов, но не вошедших в ТОП-50. Итоги будут размещены в&nbsp;приложении Choco, в разделе «Рядом&nbsp;—&nbsp;Доставка продуктов», на&nbsp;сайте Choco&nbsp;Рядом и в&nbsp;социальной сети Instagram @ryadom.kz',
        disclaimer:
          '* Сотрудники ChocoFamily, их супруги и&nbsp;близкие родственники не&nbsp;могут участвовать в&nbsp;розыгрыше.',
      },
      kk: {
        title: 'Нәтижелер',
        bonus_stopping:
          'Тапсырыстар есептегіші 14&nbsp;қаңтар күні 23:00-де тоқтатылады',
        announcement:
          '2025&nbsp;жылдың 15&nbsp;қаңтарында ТОП-50&nbsp;қатысушы анықталып, нәтижелер қосымшада жарияланады',
        random:
          'Рандомайзердің көмегімен  Үздік 50-ге кірмеген, бірақ 50 немесе одан да көп ұпай жинаған қатысушылар арасында Apple iPhone 16 жеңімпазы таңдалады. Қорытындылар Choco қосымшасында, «Рядом - Азык-түліктерді жеткізу» бөлімінде, Choco сайтында және Instagram желісінде @ryadom.kz парақшасында жарияланады"',
        disclaimer:
          '* ChocoFamily қызметкерлері, олардың жұбайы мен жақын туыстары ұтыс ойынына қатыса алмайды',
      },
    },
  },
};
</script>
