<template>
  <div class="SavedAddress">
    <p class="saved-address-title">
      {{ $t('suchAddressAlreadySaved') }}
    </p>

    <CustomerAddress
      class="saved-address-address"
      variant="saved-address"
      :address="address"
    />

    <UiButton
      class="saved-address-btn"
      size="lg"
      @click.native="$emit('onSelectSaved')"
    >
      {{ $t('selectThisAddress') }}
    </UiButton>
    <UiButton
      class="saved-address-btn"
      size="lg"
      variant="gray"
      @click.native="$emit('onSelectOther')"
    >
      {{ $t('provideAnotherAddress') }}
    </UiButton>
  </div>
</template>

<script>
import CustomerAddress from '@pure-ui/components/Address/CustomerAddress/CustomerAddressOld.vue';
import UiButton from '@pure-ui/components/UiButton/UiButton.vue';

export default {
  name: 'SavedAddress',
  components: { CustomerAddress, UiButton },
  props: {
    address: {
      type: Object,
      required: true,
    },
  },
  i18n: {
    messages: {
      ru: {
        suchAddressAlreadySaved: 'Такой адрес уже сохранен',
        selectThisAddress: 'Выбрать этот адрес',
        provideAnotherAddress: 'Указать другой адрес',
      },
      kk: {
        suchAddressAlreadySaved: 'Бұл мекенжай бұрын сақталған',
        selectThisAddress: 'Осы мекенжайды таңдау',
        provideAnotherAddress: 'Басқа мекенжай көрсету',
      },
    },
  },
};
</script>

<style scoped>
.saved-address-title {
  @apply ml-1 text-lg font-bold;
}
.saved-address-address {
  @apply mt-4 p-3 bg-sirius-gray-200 rounded-3xl;
}
.saved-address-btn {
  @apply w-full font-bold text-lg mt-7;
}
.saved-address-btn:last-child {
  @apply mt-3;
}
</style>
