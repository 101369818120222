import MainView from '../views/main/view.vue';
import SearchView from '../views/search/view.vue';
import CategoryView from '../views/category/view.vue';
import CategorySearchView from '../views/category-search/view.vue';
import CartView from '../views/cart/view.vue';

import OrdersView from '../views/orders/view.vue';
import OrderView from '../views/order/view.vue';

import PreorderView from '../views/preorder/view.vue';
import AddressView from '../views/address/view.vue';
import FaqView from '../views/faq/view.vue';

import ProblemsView from '../views/problems/view.vue';
import ProblemsOrderView from '../views/problems/views/order/view.vue';
import ProblemsRequestView from '../views/problems/views/request/view.vue';
import ProblemsPreorderView from '../views/preorder-problems/view.vue';

import ProductFullView from '../views/product-full/view.vue';

import ReferralShareView from '../views/referral-share/view.vue';

import RecipeView from '../views/recipe/view.vue';
import RecipeFullView from '../views/recipe-full/view.vue';
import ABTestView from '../views/abtest/view.vue';
import PushView from '../views/push/view.vue';
import ContextAddView from '../views/push/context-add.vue';
import ProfileView from '../views/profile/view.vue';
import ProfileCardsView from '../views/profile-cards/view.vue';
import ProfileAddressesView from '../views/profile-addresses/view.vue';
import FavoritesView from '../views/favorites/view.vue';
import ShoppingHistoryView from '../views/shopping-history/view.vue';
import AuthView from '../views/auth/view.vue';
import NY2024ContestView from '../views/ny-2024-contest/view.vue';
import MeView from '../views/me/view.vue';
import PromocodesView from '../views/promocodes/view.vue';
import PromocodeView from '../views/promocode/view.vue';
import MicromarketView from '../views/micromarket/view.vue';
import CashBacksView from '../views/cashbacks/view.vue';
import DocsView from '../views/docs/view.vue';
import RemoveChocoUserView from '../views/remove-choco-user/view.vue';
import CatalogView from '../views/catalog/view.vue';
const DeeplinksView = () => import('../views/deeplinks/view.vue');
const IINView = () => import('../views/iin/view.vue');

const docs = [
  {
    component: DocsView,
    path: '/docs',
    name: 'docs',
  },
  {
    component: IINView,
    path: '/iin',
    name: 'iin',
  },
];
const cashbacks = [
  {
    component: CashBacksView,
    path: '/cashbacks',
    name: 'cashbacks',
    meta: {
      protected: true,
    },
  },
];
const deeplinks = [
  {
    component: DeeplinksView,
    path: '/deeplinks/:type',
    name: 'deeplinks',
    props: true,
  },
];
const profile = [
  {
    path: '/me',
    name: 'me',
    component: MeView,
    meta: {
      protected: true,
    },
  },
  {
    path: '/promocodes',
    name: 'promocodes',
    component: PromocodesView,
    meta: {
      protected: true,
    },
  },
  {
    path: '/promocode/:promocode',
    name: 'promocode',
    component: PromocodeView,
    props: true,
    meta: {
      protected: true,
    },
  },
  {
    path: '/referral-share',
    name: 'referral-share',
    component: ReferralShareView,
    meta: {},
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileView,
    props: true,
    meta: {
      protected: true,
    },
  },
  {
    path: '/profile/cards',
    name: 'profile-cards',
    component: ProfileCardsView,
    props: true,
    meta: {
      protected: true,
    },
  },
  {
    path: '/profile/addresses',
    name: 'profile-addresses',
    component: ProfileAddressesView,
    props: true,
    meta: {},
  },
];
const orders = [
  {
    path: '/orders',
    name: 'orders',
    component: OrdersView,
    props: true,
    meta: {
      protected: true,
    },
  },
  {
    path: '/order/:id',
    name: 'order',
    props: true,
    component: OrderView,
    meta: {
      protected: true,
    },
  },
];
const problems = [
  {
    path: '/problems/:id',
    name: 'problems',
    component: ProblemsView,
    props: true,
    meta: {
      protected: true,
    },
  },
  {
    path: '/problems/:id/order/:type',
    name: 'problems-order',
    component: ProblemsOrderView,
    props: true,
    meta: {
      protected: true,
    },
  },
  {
    path: '/problems/:id/request/:type/',
    name: 'problems-request',
    component: ProblemsRequestView,
    props: true,
    meta: {
      protected: true,
    },
  },
];
const preorder = [
  {
    path: '/problems/preorder/:id',
    name: 'problems-preorder',
    component: ProblemsPreorderView,
    props: true,
  },
  {
    path: '/preorder/:id',
    name: 'preorder',
    props: true,
    component: PreorderView,
  },
];
const routes = [
  ...deeplinks,
  ...orders,
  ...profile,
  ...problems,
  ...preorder,
  ...cashbacks,
  ...docs,
  {
    path: '/',
    name: 'main',
    component: MainView,
  },
  {
    path: '/search',
    name: 'search',
    component: SearchView,
  },
  {
    path: '/auth',
    name: 'auth',
    component: AuthView,
  },
  {
    path: '/category/:id',
    name: 'category',
    component: CategoryView,
    props: true,
  },
  {
    path: '/category/:id/search',
    name: 'category-search',
    component: CategorySearchView,
    props: true,
  },
  {
    path: '/cart',
    name: 'cart',
    component: CartView,
  },

  {
    path: '/address/:id',
    name: 'address',
    component: AddressView,
    props: true,
    meta: { deviceId: true },
  },
  {
    path: '/faq',
    name: 'faq',
    component: FaqView,
  },
  {
    path: '/product/:id',
    name: 'product-full',
    component: ProductFullView,
    props: true,
  },
  {
    path: '/recipe',
    name: 'recipe',
    component: RecipeView,
    props: true,
  },
  {
    path: '/recipe/:id',
    name: 'recipe-full',
    component: RecipeFullView,
    props: true,
  },
  {
    path: '/abtest',
    name: 'abtest',
    component: ABTestView,
  },
  {
    path: '/push',
    name: 'push',
    component: PushView,
  },
  {
    path: '/deeplink/context',
    name: 'deeplink-context',
    component: ContextAddView,
  },
  {
    path: '/favorites',
    name: 'favorites',
    component: FavoritesView,
    meta: {
      protected: true,
    },
  },
  {
    path: '/shopping-history',
    name: 'shopping-history',
    component: ShoppingHistoryView,
    meta: {
      protected: true,
    },
  },
  {
    path: '/micromarket',
    name: 'micromarket',
    component: MicromarketView,
  },
  {
    path: '/remove-choco-user',
    name: 'remove-choco-user',
    component: RemoveChocoUserView,
  },
  {
    path: '/catalog',
    name: 'catalog',
    component: CatalogView,
  },
  {
    path: '/ny-contest-2024',
    name: 'ny-contest-2024',
    component: NY2024ContestView,
  },
];

export default routes;
