/*
  Допускаем, что сервисы могут ссылаться друг на друга.
  Только сервисы
*/

import { store } from '@/store';
import { api } from '@shared/services/api';
import { PromocodesService } from '@services/promocodes';
import { DeliveryConfigService } from '@services/delivery-config';
import { PromocodeGiftService } from '@services/promocode-gift';
import { rApplyProducts } from '@shared/services/api/apis';
import { Logger as LOG } from '@shared/services/logger';
import { eventBus } from '@/utils/plugins/event-bus';
import { CART_EVENTS, CartEmitter } from './events';
import { removeCoffeeFromCart } from './lib/remove-coffee-from-cart';

function init() {
  eventBus.on('eb_on_address_change', removeCoffeeFromCart);
  eventBus.on('eb_init_page_load_done', removeCoffeeFromCart);
  eventBus.on('eb_on_address_update', removeCoffeeFromCart);
}

/**
 * Проверяет что промокод может быть применён
 * @param {string} promocode
 * @returns {Promise}
 */
async function applyPromocode(promocode) {
  const wId = store.getters['delivery/warehouseId'];
  const abGroups = store.getters['abtest/testGroups'];
  const isDeliveryFreeReached =
    store.getters['paidOrder/isDeliveryFreeReached'];

  // Если условия изменились, может отправиться
  // даже если не должен — поэтому сбрасываем
  store.commit('cart/SET_PROMOCODE', '');
  store.commit('cart/SET_LOADING', true);

  try {
    const r = await api.lavka.applyPromocode(wId, promocode, abGroups);

    await PromocodeGiftService.applyGiftProduct(r.data?.cartPromocodeData, wId);
    const isGiftProductOutOfStock =
      store.getters['promocodes/giftProductOutOfStock'];

    if (
      !(r.data?.cartPromocodeData?.freeDelivery && isDeliveryFreeReached) &&
      !isGiftProductOutOfStock
    ) {
      LOG.event('applyPromocode', {
        'r.data': r.data,
        isDeliveryFreeReached,
      });
      store.commit('cart/SET_PROMOCODE', promocode);
      store.commit('cart/UPDATE_CART', r.data);
      DeliveryConfigService.updateIsPaymentConfigShown();
      PromocodesService.setAppliedPromocode(promocode);
    } else {
      await conditionsFailedReset(wId, abGroups);
    }

    return r;
  } catch (err) {
    PromocodeGiftService.resetGiftProduct();
    if (err.errorData?.conditions_failed) {
      await conditionsFailedReset(wId, abGroups);
      throw err;
    } else {
      throw err;
    }
  } finally {
    store.commit('cart/SET_LOADING', false);
  }
}

const conditionsFailedReset = async (wId, abGroups) => {
  const data = await api.lavka.fetchCart(wId, abGroups);

  store.commit('cart/UPDATE_CART', data.data);
};

function setPromocode(promocode) {
  store.commit('cart/SET_PROMOCODE', promocode);

  if (!promocode) {
    PromocodesService.resetAppliedPromocodes();
    // PromocodeGiftService.resetGiftProduct();
  }
}

/**
 * Удаляет применённый промокод и пересчитывает корзину
 */
function resetPromocode() {
  setPromocode('');
  store.dispatch('cart/FETCH_CART');
}

/**
 * @returns {string} промокод
 */
function getAppliedPromocode() {
  return store.getters['cart/promocode'];
}

function applyCurrentPromocode() {
  applyPromocode(getAppliedPromocode());
}

function applyProducts(products) {
  const warehouseId = store.getters['delivery/warehouseId'];
  const abGroups = store.getters['abtest/testGroups'];
  return rApplyProducts(products, warehouseId, abGroups).then(({ data }) => {
    store.commit('cart/UPDATE_CART', data);
    CartEmitter.emit(CART_EVENTS.ON_CART_CHANGED);
  });
}

export const CartService = {
  init,
  applyPromocode,
  setPromocode,
  resetPromocode,
  applyCurrentPromocode,
  applyProducts,
  removeCoffeeFromCart,
};
