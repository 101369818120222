// import { Analitycs, EVENTS } from '@shared/services/analitycs';
// import { rFetchAddressById } from '@shared/services/api/apis';
// import { Logger as LOG } from '@shared/services/logger';
// import { required } from 'vuelidate/lib/validators';
import { AddressService } from '@services/address';
import { CityService } from '@services/city';
import { MapService } from '@services/map';
import { mapGetters } from 'vuex';

export default {
  inject: ['toast'],
  data() {
    return {};
  },
  computed: {
    ...mapGetters('user', ['addresses']),
  },
  methods: {
    checkIsSavedAddress(address, addresses, isPrivate = false) {
      if (!addresses || addresses.length === 0) return null;

      const toString = x => (x !== null && x !== undefined ? x.toString() : '');

      const sameAddresses = [...addresses].filter(e => {
        if (isPrivate) {
          return (
            toString(address.street) === toString(e.street) &&
            e.is_private_home === true
          );
        }
        return (
          toString(address.street) === toString(e.street) &&
          e.is_private_home === false &&
          toString(address.flat_number) === toString(e.flat_number) &&
          toString(address.entrance) === toString(e.entrance) &&
          toString(address.floor) === toString(e.floor)
        );
      });
      return sameAddresses && sameAddresses.length > 0
        ? sameAddresses[0]
        : null;
    },
    submit(form, GeoObject, id, isExtended = false, from) {
      if (!GeoObject) {
        this.toast.show(this.$t('addressNotSelected'), 'error');
        return;
      }

      let parsed = MapService.parseGeoObject(GeoObject);
      let cityId = CityService.findCityIdByName(parsed.cityName);
      let name = (form.name || this.$t('address')).toString().slice(0, 30);

      let payload = {
        ...form,
        ...parsed,
        name,
        city_id: cityId,
        domofon_code: form.doorbell || form.domofon_code,
      };
      const savedAddress = this.checkIsSavedAddress(
        payload,
        this.addresses,
        !!form.is_private_home
      );

      if (savedAddress && id === 'create') {
        return Promise.resolve({ savedAddress });
      }

      let request = Number(id)
        ? AddressService.updateAddress
        : AddressService.createAddress;
      if (Number(id)) payload.id = id;

      return request(payload, from)
        .then(() => {
          let msg = Number(id) ? 'addressUpdated' : 'addressCreated';
          this.toast.show(this.$t(msg), 'success');
          if (isExtended) {
            this.$eventBus.emit('eb_open_extended_delivery_modal');
          }
        })
        .catch(err => {
          this.toast.show(err.message, 'error');
        });
    },
  },
  i18n: {
    messages: {
      ru: {
        address: 'Адрес',
        addressCreated: 'Адрес добавлен',
        addressUpdated: 'Изменения сохранены',
        selectAddress: 'Укажите адрес доставки',
        addressNotSelected: 'Адрес не указан',

        privateHouse: 'Частный дом',
        requiredField: 'Обязательное поле',
        streetBuildingHouse: 'Улица и здание/дом',
        continue: 'Продолжить',
        saveChanges: 'Сохранить изменения',
        addressNamePlaceholder: 'Название адреса, например «Дом», «Работа»',
        addressNamePlaceholderSmall: 'Название адреса, например «Дом»',
        selectBuildingOrHouse: 'Выберите здание или дом',
      },
      kk: {
        address: 'Мекен-жай',
        addressCreated: 'Мекенжай қосылды',
        addressUpdated: 'Өзгерістер сақталды',
        selectAddress: 'Мекенжайды таңдаңыз',
        addressNotSelected: 'Мекенжай таңдалмаған',

        privateHouse: 'Жер үй',
        requiredField: 'Міндетті жол',
        streetBuildingHouse: 'Көше, ғимарат, үй',
        continue: 'Жалғастыру',
        saveChanges: 'Өзгерістерді сақтау',
        addressNamePlaceholder: 'Мекен-жай аты, мысалға "Уй", "Жұмыс"',
        addressNamePlaceholderSmall: 'Мекен-жай аты, мысалға "Уй"',
        selectBuildingOrHouse: 'Үй немесе ғимарат таңдаңыз',
      },
    },
  },
  render() {
    return this.$scopedSlots.default({
      submit: this.submit,
    });
  },
};
