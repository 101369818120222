
export const LoyaltyModule = {
  namespaced: true,
  state: {
    available: false,
  },
  getters: {
    available(state) {
      return state.available;
    },
    count(state, getters, rootState, rootGetters) {
      return rootGetters['user/userProfile']?.balance;
    },
  },
  mutations: {
    SET_AVAILABLE(state, value) {
      state.available = value;
    },
  },
};
