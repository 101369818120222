<template>
  <MainLayout :with-footer="false" class="ProfileAddressesView">
    <template v-if="$isWebView" #header>
      <ToolBar>
        {{ $t('myAddresses') }}
      </ToolBar>
    </template>
    <ProfileLayout :container-width="688">
      <template v-if="$isWebView">
        <div class="addresses">
          <EmptyAddresses v-if="!addresses.length" @onCreate="addAddress" />
          <div v-else class="addresses__list">
            <CustomerAddress
              v-for="address in addresses"
              :key="address.id"
              :address="address"
              :title="addressTitle(address)"
              :description="addressDescr(address)"
              :active="deliveryAddressId === address.id"
              class="addresses__item"
              @onSelect="onSelect(address)"
              @onDots="onDots(address)"
            />
          </div>

          <div class="addresses__btn">
            <AddAddressButton @onCreate="addAddress" />
          </div>
        </div>
      </template>

      <template v-else>
        <div class="addresses">
          <EmptyAddresses v-if="!addresses.length" old @onCreate="addAddress" />
          <template v-else>
            <h1 class="text-2xl md:text-32 font-bold mb-3 md:mb-10">
              {{ $t('myAddresses') }}
            </h1>

            <div
              v-for="address in addresses"
              :key="address.id"
              class="mb-7 last:mb-0"
            >
              <CustomerAddressOld
                variant="desktop-address-page"
                :address="address"
                :left-icon="false"
                :active="deliveryAddressId === address.id"
                :controls="['edit', 'delete']"
                @onSelect="onSelect"
                @onEdit="onEdit"
                @onDelete="onDelete"
              />
            </div>

            <UiButton
              size="lg"
              class="w-full md:w-min whitespace-nowrap mt-14 px-10 font-bold text-lg"
              @click="addAddress"
            >
              <div class="mr-auto flex items-center">
                <svg
                  class="fill-current"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke=""
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M11 13V21H13V13H21V11H13V3H11V11H3V13H11Z"
                  />
                </svg>
                <span class="pl-4">
                  {{ $t('toAddNewAddress') }}
                </span>
              </div>
            </UiButton>
          </template>
        </div>
      </template>
    </ProfileLayout>
  </MainLayout>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { AddressService } from '@services/address';
import { Analitycs, EVENTS } from '@shared/services/analitycs';
import { getAddressText } from '@services/address/lib/get-address-text';
import CustomerAddressOld from '@pure-ui/components/Address/CustomerAddress/CustomerAddressOld.vue';
import AddAddressButton from '@pure-ui/components/Address/AddAddressButton/AddAddressButton.vue';
import CustomerAddress from '@pure-ui/components/Address/CustomerAddress/CustomerAddress.vue';
import EmptyAddresses from '@pure-ui/components/Address/EmptyAddresses/EmptyAddresses.vue';
import ProfileLayout from '@shared/components/layout/ProfileLayout.vue';
import MainLayout from '@shared/components/layout/MainLayout.vue';
import UiButton from '@ui/UiButtonNew.vue';
import ToolBar from '@layout/ToolBar.vue';

export default {
  name: 'ProfileAddressesView',
  components: {
    CustomerAddressOld,
    AddAddressButton,
    CustomerAddress,
    EmptyAddresses,
    ProfileLayout,
    MainLayout,
    UiButton,
    ToolBar,
  },
  inject: ['popup', 'toast'],
  data() {
    return {};
  },
  computed: {
    ...mapState('user', ['addresses']),
    ...mapGetters('user', ['deliveryAddressId']),
  },
  mounted() {
    Analitycs.logEvent(EVENTS.VIEWED_ADDRESS, {
      address_list: this.addresses,
      from: 'profile',
    });
  },
  methods: {
    addressTitle(address) {
      return getAddressText(address, {
        lang: this.$i18n.locale,
        onlyName: true,
      });
    },
    addressDescr(address) {
      return getAddressText(address, {
        lang: this.$i18n.locale,
        withCity: true,
      });
    },
    onDelete(address) {
      this.$eventBus.emit('eb_remove_address_with_confirmation', {
        onConfirm: () => {
          AddressService.removeAddress(address.id, 'profile')
            .then(() => {
              this.toast.show(this.$t('addressHasBeenRemoved'), 'success');
            })
            .catch(err => {
              this.toast.show(err.message, 'error');
              throw err;
            });
        },
      });
    },
    onEdit(address) {
      this.$eventBus.emit('eb_open_address_create_container', {
        id: address.id,
        from: 'profile',
      });
    },
    onSelect(address) {
      AddressService.changeAddress(address)
        .then(() => {
          this.toast.show(this.$t('addressSelected'), 'success');
          if (this.$isWebView) this.$router.go(-1);
        })
        .catch(err => {
          this.toast.show(err.message, 'error');
        });
    },
    addAddress() {
      this.$eventBus.emit('eb_open_address_create_container', {
        from: 'profile',
      });
    },
    onDots(address) {
      this.$eventBus.emit('eb_open_address_edit_options_modal', {
        address,
        from: 'profile',
      });
    },
  },
  i18n: {
    messages: {
      ru: {
        myAddresses: 'Мои адреса',
        toAddNewAddress: 'Добавить новый адрес',
        addressHasBeenRemoved: 'Адрес успешно удален',
        addressSelected: 'Адрес выбран основным',
      },
      kk: {
        myAddresses: 'Менің мекенжайларым',
        toAddNewAddress: 'Жаңа мекенжайды қосу',
        addressHasBeenRemoved: 'Мекенжай жойылды',
        addressSelected: 'Мекенжай басты болып таңдалды',
      },
    },
  },
};
</script>

<style scoped>
.addresses {
  @apply pt-2 px-4 md:px-0 md:pt-0;
  padding-bottom: 36px;
}
.addresses__item {
  @apply pt-3 pb-[18px] border-b-2 last:border-b-0;
  border-color: rgba(241, 242, 247, 0.7);
}
.addresses__btn {
  @apply p-4 pt-8 fixed w-full bottom-0 left-0;
  background: linear-gradient(
    181deg,
    rgba(255, 255, 255, 0) 0.51%,
    #fff 22.76%
  );
}
</style>
