import { rFetchDeliveryConfigs } from '@shared/services/api/apis';
import { DevConfigs } from '@/utils/dev-config';
import { store } from '@/store';

function setIsDistant(value) {
  store.commit('paidOrder/SET_IS_DISTANT', value);
}
function setServiceFeePrice(value) {
  store.commit('paidOrder/SET_SERVICE_FEE_PRICE', value);
}
function setDefaultPaymentConfig(value) {
  store.commit('paidOrder/SET_DEFAULT_PAYMENT_CONFIGS', value);
}
function setPaidDeliveryType(value) {
  store.commit('paidOrder/SET_PAID_DELIVERY_TYPE', value);
}
function setPaymentConfigShown(value) {
  store.commit('paidOrder/SET_SHOW_PAYMENT_CONFIG', value);
}
function setHighPricePaymentConfigs(value) {
  store.commit('paidOrder/SET_HIGH_PRICE_PAYMENT_CONFIGS', value);
}

function distantDeliveryMocks() {
  setIsDistant(true);
  setDefaultPaymentConfig([
    {
      price_to: 3999,
      price_from: 0,
      delivery_price: 500,
    },
    {
      price_to: null,
      price_from: 4000,
      delivery_price: 0,
    },
  ]);
}
function highDeliveryMocks() {
  setHighPricePaymentConfigs([
    {
      price_to: 3999,
      price_from: 0,
      delivery_price: 209,
    },
    {
      price_to: null,
      price_from: 4000,
      delivery_price: 0,
    },
  ]);
}
function defaultDeliveryMocks() {
  setIsDistant(false);
  setHighPricePaymentConfigs(null);
  setDefaultPaymentConfig([
    {
      price_to: 3999,
      price_from: 0,
      delivery_price: 149,
    },
    {
      price_to: null,
      price_from: 4000,
      delivery_price: 0,
    },
  ]);
}

/**
 * Здесь мы определяем нужно ли показывать плашку о платной доставке или нет
 */
function updateIsPaymentConfigShown() {
  const configs = store.getters['paidOrder/paymentConfigs'];
  const minPrice = store.getters['paidOrder/minPriceForFreeDelivery'];
  const minCheck = store.getters['delivery/minCheck'];
  const cartTotalPriceWithDiscount =
    store.getters['cart/cartTotalPriceWithDiscount'];
  const samplingTotalPrice = store.getters['samplings/samplingTotalPrice'];
  const availablePromocodeGiftProductPrice =
    store.getters['promocodes/availablePromocodeGiftProduct']?.price || 0;

  const totalPrice =
    cartTotalPriceWithDiscount +
    samplingTotalPrice +
    availablePromocodeGiftProductPrice;

  const freeDeliveryPromo = store.getters['paidOrder/freeDeliveryPromocode'];

  let value = true;
  if (!configs) value = false;
  if (totalPrice < minPrice || totalPrice < minCheck) value = true;
  else value = false;
  if (freeDeliveryPromo && totalPrice > minCheck) value = false;

  setPaymentConfigShown(value);
}

/**
 * Устанавливает текст применённого в корзине промокода
 * на бесплатную доставку.
 * Это значение выводится в модалке Условия заказа.
 * После установки метод вычисляет, нужно ли показывать плашку
 * о платной доставке, и скрывает её если нужно.
 * Если промокод удалили, здесь так же нужно сбросить!
 */
function setFreeDeliveryPromocode(value) {
  store.commit('paidOrder/SET_FREE_DELIVERY_PROMOCODE', value);
  updateIsPaymentConfigShown();
}

function freeDeliveryPromoMocks() {
  setFreeDeliveryPromocode('FREE_DELIVERY_PROMOCODE');
}
/**
 * Делвем запрос в бек и получаем нужные конфиги по оплатам заказа
 * платной доставки,
 * сервисного сбора,
 * является ли он дальним
 * тип платной доставки
 * @returns {Promise}
 */
function loadConfig() {
  const { isAuthorized } = store.getters;
  const addressId = store.getters['user/deliveryAddressId'];
  const warehouseId = store.getters['delivery/warehouseId'];
  if (!addressId) {
    return Promise.resolve();
  }

  return new Promise(resolve => {
    rFetchDeliveryConfigs(addressId, warehouseId, isAuthorized)
      .then(({ data }) => {
        let type = null;
        let deliveryCosts = null;
        if (data && data.delivery_costs && data.delivery_costs.length) {
          deliveryCosts = data.delivery_costs;
          type = data.type;
        }
        let highDeliveryCosts = null;
        if (data.high_delivery_costs && data.high_delivery_costs.length) {
          highDeliveryCosts = data.high_delivery_costs;
        }

        setIsDistant(!!data.is_distant);
        setServiceFeePrice(data.service_fee ?? null);
        setPaidDeliveryType(type);
        setDefaultPaymentConfig(deliveryCosts);
        setHighPricePaymentConfigs(highDeliveryCosts);
      })
      .catch(err => {
        console.error(err);
        setIsDistant(false);
        setServiceFeePrice(null);
        setPaidDeliveryType(null);
        setDefaultPaymentConfig(null);
        setHighPricePaymentConfigs(null);
      })
      .finally(() => {
        if (DevConfigs.isDev) {
          setServiceFeePrice(100);
        }
        updateIsPaymentConfigShown();
        return resolve();
      });
  });
}

/**
 * Этот моджуль для получения параметров
 * платной доставки,
 * сервисного сбора,
 * является ли он дальним
 * */
export const DeliveryConfigService = {
  updateIsPaymentConfigShown,
  setHighPricePaymentConfigs,
  loadConfig,
  setFreeDeliveryPromocode,

  freeDeliveryPromoMocks,
  distantDeliveryMocks,
  defaultDeliveryMocks,
  highDeliveryMocks,
};
