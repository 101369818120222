<template>
  <div class="InputRow">
    <form
      class="InputRow__inputWrap"
      @submit.prevent="$emit('onSubmit', promocode)"
    >
      <UiInput
        :value="promocode"
        size="lg"
        :placeholder="$t('yourPromocode')"
        :invalid="!!errorText"
        :valid="!!successMsg"
        transform-uppercase
        transform-trim
        bold-text
        @onInput="$emit('onInput', $event)"
      />
      <UiButton
        v-if="showApplyButton"
        class="absolute t-0.5 r-0.5 font-bold"
        style="top: 4px; right: 4px; min-width: 152px"
      >
        {{ $t('apply') }}
      </UiButton>
      <UiInlineIcon
        v-if="appliedWithErrorText"
        name="tooltip"
        class="InputRow__iconError"
      />
      <UiInlineIcon
        v-if="!!successMsg"
        name="success-circle"
        class="InputRow__iconSuccess"
      />
      <UiInlineIcon v-if="noteMsg" name="tooltip" class="InputRow__iconNote" />
      <div v-if="appliedDiscount" class="InputRow__discount">
        {{ appliedDiscount }} ₸
      </div>
    </form>
    <div v-if="!!errorText" class="InputRow__errorMsg">
      {{ errorText }}
    </div>
    <div v-if="successMsg" class="InputRow__successMsg">
      {{ successMsg }}
    </div>
    <div v-if="noteMsg" class="InputRow__noteMsg">
      <div v-html="noteMsg" />
    </div>
  </div>
</template>

<script>
import UiInput from '@pure-ui/components/UiInput/UiInput.vue';
import UiButton from '@pure-ui/components/UiButton/UiButton.vue';
import UiInlineIcon from '@pure-ui/components/UiInlineIcon/UiInlineIcon.vue';

export default {
  name: 'InputRow',
  components: { UiInput, UiButton, UiInlineIcon },
  props: {
    promocode: {
      type: String,
      default: '',
    },
    notAppliedWithErrorText: {
      type: String,
      default: '',
    },
    appliedWithErrorText: {
      type: String,
      default: '',
    },
    appliedWithWarningText: {
      type: String,
      default: '',
    },
    appliedDiscount: {
      type: Number,
      default: 0,
    },
    appliedFreeDelivery: {
      type: Boolean,
      default: false,
    },
    freeDeliveryNotApplied: {
      type: Boolean,
      default: false,
    },
    giftProduct: {
      type: Boolean,
      default: false,
    },
    giftProductOutOfStock: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    showApplyButton() {
      return !(
        this.appliedWithErrorText ||
        this.appliedDiscount ||
        this.giftProduct ||
        this.appliedFreeDelivery ||
        this.freeDeliveryNotApplied
      );
    },
    errorText() {
      return this.appliedWithErrorText || this.notAppliedWithErrorText;
    },
    successMsg() {
      if (this.appliedFreeDelivery || this.appliedDefault) {
        return this.$t(
          this.appliedDefault
            ? 'promocodeApplied'
            : 'freeDeliveryPromocodeApplied'
        );
      }
      return null;
    },
    noteMsg() {
      if (this.freeDeliveryNotApplied) {
        return this.$t('freeDeliveryPromocodeNotApplied');
      }
      if (this.giftProduct && this.giftProductOutOfStock) {
        return this.$t('outOfStock');
      }
      return null;
    },
    appliedDefault() {
      return (
        this.appliedDiscount ||
        (this.giftProduct && !this.giftProductOutOfStock)
      );
    },
  },
  i18n: {
    messages: {
      ru: {
        apply: 'Применить',
        yourPromocode: 'Введите промокод',
        promocodeApplied: 'Промокод применён',
        freeDeliveryPromocodeApplied:
          'Промокод на бесплатную доставку применён',
        outOfStock:
          'Промокод не&nbsp;применён, так как товара нет в&nbsp;наличии.',
        freeDeliveryPromocodeNotApplied: `
          <p>
            Промокод не применён, так как в&nbsp;заказе уже действуют
            условия бесплатной доставки.
          </p>
          <p style="margin-top: 12px">
            Его можно будет применить
            в следующем заказе с платной доставкой.
          </p>
        `,
      },
      kk: {
        apply: 'Қолдану',
        yourPromocode: 'Промокод енгізіңіз',
        freeDeliveryPromocodeApplied: 'Промокод тегін жеткізу үшін қолданылды',
        outOfStock: 'Промокод қолданылмады, себебі тауар қолжетімді емес.',
        freeDeliveryPromocodeNotApplied: `
          <p>
            Промокод қолданылмады, себебі бұл тапсырыста жеткізу тегін шарты бар.
          </p>
          <p style="margin-top: 12px">
            Оны келесі ақылы тапсырыстарда қолдануға болады
          </p>
        `,
      },
    },
  },
};
</script>

<style scoped>
.InputRow {
  padding-top: 24px;
}

.InputRow__inputWrap {
  position: relative;
}

.InputRow__errorMsg {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin-top: 4px;
  color: #f91f01;
}

.InputRow__successMsg {
  @apply text-sirius-green-200;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin-top: 4px;
}

.InputRow__noteMsg {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin-top: 4px;
  color: #767485;
}

.InputRow__iconError {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 16px;
  margin-top: auto;
  margin-bottom: auto;
  color: #f91f01;
  height: max-content;
}

.InputRow__iconSuccess {
  @apply text-sirius-green-200;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 12px;
  margin-top: auto;
  margin-bottom: auto;
  height: max-content;
}

.InputRow__iconNote {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 12px;
  display: flex;
  align-items: center;
  margin-top: auto;
  margin-bottom: auto;
  color: #7b7a8c;
}

.InputRow__discount {
  color: rgba(5, 191, 13, 1);
  white-space: nowrap;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px; /* 112.5% */
  letter-spacing: 0.08px;
  position: absolute;
  right: 52px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}
</style>
