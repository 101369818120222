<template>
  <div class="PromocodeMobile">
    <MPromocodeField
      v-bind="state"
      :loading="loading"
      @click="onFieldClick"
      @onSubmit="onSubmit"
      @onModalChange="onModalChange"
    />
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters } from 'vuex';
import { AddressEmitter, ADDRESS_EVENTS } from '@services/address/emitter';
import { DeliveryConfigService } from '@services/delivery-config';
import { CartEmitter, CART_EVENTS } from '@services/cart/events';
import { Analitycs, EVENTS } from '@shared/services/analitycs';
import { PromocodesService } from '@services/promocodes';
import { PromocodeGiftService } from '@services/promocode-gift';
import { AuthService } from '@shared/services/auth';
import { Logger } from '@shared/services/logger';
import { RahmetApp } from '@shared/RahmetApp';
import { CartService } from '@services/cart';
import MPromocodeField, {
  getState,
} from '@pure-ui/components/MPromocodeField/MPromocodeField.vue';

export default {
  name: 'PromocodeMobile',
  components: {
    MPromocodeField,
  },
  data: () => ({
    state: getState('Default'),
    loading: false,
  }),
  computed: {
    ...mapGetters('promocodes', [
      'promocodeGiftProduct',
      'giftProductOutOfStock',
    ]),
    ...mapGetters('cart', ['promocode']),
  },
  watch: {
    promocode(value) {
      if (!value) {
        this.setState('Default');
      }
    },
  },
  mounted() {
    CartEmitter.on(CART_EVENTS.ON_CART_CHANGED, this.onCartChanged);
    CartEmitter.on(CART_EVENTS.ON_CART_FETCHED, this.onCartFetched);
    AddressEmitter.on(ADDRESS_EVENTS.ON_ADDRESS_CHANGE, this.onAddressChanged);
    if (this.$store.getters.isAuthorized) {
      this.init();
    }
  },
  beforeDestroy() {
    CartEmitter.off(CART_EVENTS.ON_CART_CHANGED, this.onCartChanged);
    CartEmitter.off(CART_EVENTS.ON_CART_FETCHED, this.onCartFetched);
    AddressEmitter.off(ADDRESS_EVENTS.ON_ADDRESS_CHANGE, this.onAddressChanged);
  },
  methods: {
    init() {
      if (!this.$route.name === 'cart') return Promise.resolve();
      /*
        Пока не работает. Появится, когда найдём способ
        передавать в реферальной ссылке промокод,
        чтобы сразу открывалась страница
      */
      // if (ReferralService.hasPromocode()) {
      //   this.apply(ReferralService.getPromocode());
      // }
      return PromocodesService.loadActiveToStore().then(() => {
        PromocodesService.applyClosestIfNo();
        if (PromocodesService.hasAppliedPromocode()) {
          return this.apply(PromocodesService.getAppliedPromocode());
        }
        return Promise.resolve();
      });
    },
    onModalChange($event) {
      this.state.open = $event;
    },
    onFieldClick() {
      RahmetApp.hapticSelection();
      if (!this.$store.getters.isAuthorized) {
        AuthService.authorize().then(() => {
          this.$store.dispatch('INIT_AUTHORIZED_FLOW');
        });
        return;
      }

      this.state.open = true;
    },
    onSubmit($event /* promocode */) {
      this.apply($event, 'submit');
      if (!$event && !$event.length) {
        return;
      }
      /*
       * Если ввели руками промокод, сохраняем его в профиле
       * чтобы он остался в случае, если покинут корзину.
       * Тогде по возвращению он снова применится
       */
      PromocodesService.savePromocode($event).catch(err => {
        Logger.event('promocode save error', err);
      });
    },
    /**
     *
     * @param {*} promocode
     * @param {*} action если передана строка submit — значит метод запущен после
     * ввода промокода руками
     */
    apply(promocode, action) {
      DeliveryConfigService.setFreeDeliveryPromocode('');
      if (action === 'submit') this.loading = true;
      // если очистили инпут и нажали Подтвердить
      if (!promocode) {
        CartService.resetPromocode();
        PromocodeGiftService.resetGiftProduct();
        this.setState('Default');
        this.loading = false;
        return;
      }

      CartService.applyPromocode(promocode)
        .then(r => {
          if (!r) {
            return;
          }
          const isDeliveryFree =
            this.$store.getters['paidOrder/isDeliveryFreeReached'];
          if (r.data.cartPromocodeData.freeDelivery) {
            if (isDeliveryFree) {
              this.setState('FreeDeliveryNotApplied', {
                promocode: promocode,
                freeDeliveryNotApplied: true,
              });
            } else {
              this.setState('FreeDelivery', { promocode: promocode });
              DeliveryConfigService.setFreeDeliveryPromocode(promocode);
            }
          } else {
            this.setState('Success', {
              promocode: promocode,
              discount: r.data.discount,
              giftProduct: !!r.data.cartPromocodeData?.promoProductId,
              giftProductOutOfStock:
                this.giftProductOutOfStock ||
                (r.data.cartPromocodeData?.promoProductId &&
                  !this.promocodeGiftProduct),
            });
          }
          Analitycs.logEvent(EVENTS.PRODUCT_PROMOCODE_APPLIED, {
            promocode: promocode,
            product_id: r.data?.cartPromocodeData?.promoProductId,
            product_price: r.data?.cartPromocodeData?.promoProductPrice,
            error: this.giftProductOutOfStock ? 'Out of stock' : '',
          });

          action === 'submit' &&
            Analitycs.logEvent(EVENTS.CART_PROMOCODE_USER_ACTION, {
              action: 'success',
              promocode: promocode,
            });
        })
        .catch(e => {
          if (e.errorData?.conditions_failed) {
            this.setState('Error', {
              promocode: promocode,
              errorMessage: e.message,
            });
          } else {
            this.setState('ModalError', {
              promocode: promocode,
              modalErrorMessage: e.message,
            });

            action === 'submit' &&
              Analitycs.logEvent(EVENTS.CART_PROMOCODE_USER_ACTION, {
                action: 'error',
                promocode: promocode,
                error: e.message,
              });
          }

          Analitycs.logEvent(EVENTS.PRODUCT_PROMOCODE_APPLIED, {
            promocode: promocode,
            error: e?.message || '',
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onCartChanged() {
      Logger.event('PromocodeMobile onCartChanged');
      if (this.state.promocode) {
        this.apply(this.state.promocode);
      }
    },
    onAddressChanged() {
      Logger.event('PromocodeMobile onAddressChanged');
      if (this.state.promocode) {
        this.apply(this.state.promocode);
      }
    },
    onCartFetched() {
      Logger.event('PromocodeMobile onCartFetched');
      if (this.state.promocode) {
        this.apply(this.state.promocode);
      }
    },
    setState(stateName, params) {
      // console.trace('PromocodeMobile.setState', stateName, params);
      this.state = getState(stateName, params);
    },
  },
  eventBusEvents: {
    eb_on_auth() {
      Logger.event('PromocodeMobile eb_on_auth');
      this.init();
    },
  },
};
</script>
