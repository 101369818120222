import { store } from '@/store';
import { $log } from '@/utils/plugins/logger';
import { DevConfigs } from '@/utils/dev-config';

export const LoyaltyService = {
  init() {
    //eventBus.on('eb_on_fetch_tests', () => {
      // console.log('LoyaltyService init', {
      //   webview: RahmetApp.isWebView(),
      //   ab: store.getters['abtest/testsObj']['loyalty'],
      //   testsObj: store.getters['abtest/testsObj'],
      // });
      // if (!RahmetApp.isWebView()) return;
      // if (!store.getters['isAuthorized']) return;
      //if (store.getters['abtest/testsObj']['loyalty'] === 'test') {
      // store.commit('loyalty/SET_AVAILABLE', true);
      // }
    //});

    // временно отключили
    store.commit('loyalty/SET_AVAILABLE', false);
    if (!DevConfigs.isProd) {
      $log('Loyalty Available2', {value : store.getters['loyalty/available']});
    }
  },
};
