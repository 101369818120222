<template>
  <div class="MBlockFreeDelivery flex pt-3 pb-4" v-on="$listeners">
    <UiInlineIcon name="promocode" style="min-width: 24px" />
    <div class="ml-3 mr-auto flex flex-col">
      <span>{{ promocode }}</span>
      <span class="MBlockFreeDelivery__msg">
        {{ $t('promocodeApplied') }}
      </span>
    </div>
    <!-- <div class="text-green-400 font-bold">{{ discount }} ₸</div> -->
    <UiInlineIcon
      class="MBlockSuccess__iconArr"
      name="arrow-right"
      style="color: #a4a2b7; min-width: 24px"
    />
  </div>
</template>

<script>
import UiInlineIcon from '@pure-ui/components/UiInlineIcon/UiInlineIcon.vue';

export default {
  name: 'MBlockFreeDelivery',
  components: {
    UiInlineIcon,
  },
  props: {
    promocode: {
      type: String,
      default: '',
    },
  },
  i18n: {
    messages: {
      ru: {
        promocodeApplied: 'Промокод на бесплатную доставку применён',
      },
      kk: {
        promocodeApplied: 'Промокод тегін жеткізу үшін қолданылды',
      },
    },
  },
};
</script>

<style>
.MBlockFreeDelivery__iconArr {
  margin-left: 6px;
  margin-right: -6px;
}

.MBlockFreeDelivery__msg {
  @apply text-sirius-green-200;
  font-size: 14px;
  line-height: 16px; /* 114.286% */
  letter-spacing: 0.07px;
}
</style>
