<template>
  <MainLayout :with-footer="false">
    <template v-if="$isWebView" #header>
      <PageHeader :from-push-event="routeFromPushEvent" />
    </template>

    <ProfileLayout>
      <UiRippleLoader v-if="loading" />
      <template v-else>
        <div class="px-4 md:px-0" style="padding-bottom: 100px">
          <h1
            v-if="!$isWebView"
            class="mb-5 md:mb-9 font-bold text-2xl md:text-32 leading-tight"
          >
            {{ $t('myOrders') }}
          </h1>
          <template v-if="!error">
            <ActivePromocodes
              :promocodes="promocodes"
              :has-referral-program="hasReferralProgram"
              @onActivate="onActivate($event)"
            />
            <ArchivePromocodes :promocodes="archivePromocodes" class="pt-4" />
          </template>
          <div v-else class="px-4 pt-4 text-sm text-center">
            {{ $t('canNotGetPromos') }}
          </div>
        </div>
        <AddPromocode @reloadPromocodesList="loadPromocodes" />
      </template>
    </ProfileLayout>
  </MainLayout>
</template>

<script>
import { mapGetters } from 'vuex';
import { PromocodesService } from '@services/promocodes';
import { Analitycs, EVENTS } from '@shared/services/analitycs';
import MainLayout from '@shared/components/layout/MainLayout.vue';
import UiRippleLoader from '@shared/components/ui/UiRippleLoader.vue';
import ProfileLayout from '@shared/components/layout/ProfileLayout.vue';

import ArchivePromocodes from './components/ArchivePromocodes.vue';
import ActivePromocodes from './components/ActivePromocodes.vue';
import AddPromocode from './components/AddPromocode.vue';
import PageHeader from './components/PageHeader.vue';

export default {
  name: 'PromocodesView',
  components: {
    ArchivePromocodes,
    ActivePromocodes,
    UiRippleLoader,
    ProfileLayout,
    AddPromocode,
    MainLayout,
    PageHeader,
  },
  inject: ['toast'],
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.routeFromPushEvent = from.name === 'push';
    });
  },
  data: () => ({
    loading: true,
    error: false,
    archivePromocodes: [],
    routeFromPushEvent: false,
  }),
  computed: {
    ...mapGetters('user', ['hasReferralProgram']),
    promocodes() {
      const applied = this.$store.getters['promocodes/appliedPromocode'];
      return this.$store.state.promocodes.promocodes.map(v => ({
        ...v,
        isAppliedInCart: v.promocode === applied,
      }));
    },
  },
  mounted() {
    this.loadPromocodes();
  },
  methods: {
    loadPromocodes() {
      Promise.all([
        PromocodesService.loadActiveToStore(),
        PromocodesService.fetchArchive(),
      ])
        // eslint-disable-next-line no-unused-vars
        .then(([_, archive]) => {
          this.archivePromocodes = archive;
          PromocodesService.applyClosestIfNo();
        })
        .catch(() => {
          this.error = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onActivate(promocode) {
      PromocodesService.setAppliedPromocode(promocode);
      Analitycs.logEvent(EVENTS.REF_PROMOCODE_PAGE_APPLY_PROMOCODE, {
        promocode,
      });
      this.toast.show(this.$t('willBeAppliedToCart'), 'success');
    },
  },
  i18n: {
    messages: {
      ru: {
        myOrders: 'Мои промокоды',
        willBeAppliedToCart: 'Промокод применён в корзине',
        canNotGetPromos: 'Не удалось получить список промокодов',
      },
      kk: {
        myOrders: 'Менің промокодтарым',
        willBeAppliedToCart: 'Себетте промокод қолданыста',
        canNotGetPromos: 'Промокодтар тізімін алу мүмкін болмады',
      },
    },
  },
};
</script>
