<template>
  <HorizontalProductCard
    :name="product.name"
    :img-src="product.path"
    :price="product.price"
    :old-price="product.old_price"
    :quantity="product.quantity || 1"
    img-mix-blend
    class="GiftsProductCard"
    :class="{ __sticker: sticker }"
    @onClick="$emit('onGoProduct', 'block')"
  >
    <!-- TODO: samplings title and price are different in design -->
    <template #photo>
      <slot name="photo">
        <GiftsPhoto
          class="GiftsProductCard__photo"
          :class="`${sticker ? 'mr-1' : ''}`"
          :src="product.path"
          :price="product.price"
          :sticker="sticker"
          @click.native.stop="$emit('onGoProduct', 'photo')"
        />
      </slot>
    </template>

    <template #right>
      <Counter> {{ product.quantity || 1 }} </Counter>
    </template>
  </HorizontalProductCard>
</template>

<script>
import GiftsPhoto from '@pure-ui/components/Gifts/GiftsPhoto/GiftsPhoto.vue';
import HorizontalProductCard from '@pure-ui/components/Product/HorizontalProductCard/HorizontalProductCard.vue';
import Counter from './components/Counter.vue';

export default {
  name: 'GiftsProductCard',
  components: {
    HorizontalProductCard,
    GiftsPhoto,
    Counter,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    sticker: Boolean,
  },
};
</script>

<style scoped>
.GiftsProductCard {
  @apply p-2 pb-3 bg-sirius-gray-200 rounded-2xl;
}
.GiftsProductCard.__sticker {
  @apply bg-sirius-orange-500;
}
.GiftsProductCard__photo {
  @apply bg-white;
}
</style>
