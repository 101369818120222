import { rFetchWarehouses } from '@shared/services/api/apis';

const getPolygonsFromResponse = warehouses => {
  const polygons = {
    regular: [],
    extended: [],
  };

  warehouses.forEach(element => {
    const deliveryZone = JSON.parse(element.delivery_zone);

    const extended = deliveryZone.features?.[1]?.geometry;
    // полигон дальней доставки
    if (extended) {
      extended.coordinates[0].map(v => v.reverse());
      polygons.extended.push({
        type: 'extended',
        coordinates: extended.coordinates,
      });
    }

    // полигон обычной быстрой доставки
    const regular = deliveryZone.features?.[0]?.geometry;
    if (regular) {
      regular.coordinates[0].map(v => v.reverse());
      polygons.regular.push({
        type: extended ? 'regularNearExtended' : 'regular',
        coordinates: regular.coordinates,
      });
    }
  });

  return polygons;
};

export const fetchPolygons = () =>
  rFetchWarehouses().then(({ data }) => getPolygonsFromResponse(data));
