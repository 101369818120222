<template>
  <div class="IphoneLottery">
    <div class="IphoneLottery__block">
      <NyPageSubtitle class="IphoneLottery__title" style="color: #242424">
        {{ $t('title') }}
      </NyPageSubtitle>

      <div class="IphoneLottery__text">
        <p class="IphoneLottery__p" v-html="$t('text1')"></p>
        <p
          v-if="whenDone"
          class="IphoneLottery__p IphoneLottery__p--note"
          v-html="$t('text2')"
        ></p>
      </div>

      <div class="IphoneLottery__product-img"></div>
    </div>
  </div>
</template>

<script>
import NyPageSubtitle from '@/views/ny-2024-contest/components/NyPageSubtitle/NyPageSubtitle.vue';

export default {
  name: 'IphoneLottery',
  components: {
    NyPageSubtitle,
  },
  data() {
    return {
      whenDone: true,
    };
  },
  i18n: {
    messages: {
      kk: {
        title: 'iPhone 16 ұтысының нәтижелері',
        text1: `Супер жүлде – iPhone 16 Pro ұтысы 15 қаңтар күні Instagram әлеуметтік желісінде (@ryadom.kz) тікелей эфирде 50 және одан да көп ұпай жинап, ТОП-50 қатарына кірмеген қатысушылар арасында өтті.`,
        text2: `Ұтыс жеңімпазы&nbsp;– +7&nbsp;***&nbsp;***&nbsp;16&nbsp;55 нөмірлі пайдаланушы.`,
      },
      ru: {
        title: 'Результаты розыгрыша iPhone 16',
        text1: `Розыгрыш супер-приза&nbsp;iPhone 16 Pro состоялся в&nbsp;прямом эфире&nbsp;в социальной&nbsp;сети Instagram (@ryadom.kz)&nbsp;15–го января&nbsp;среди участников, набравших&nbsp;50&nbsp;и&nbsp;более баллов, не&nbsp;вошедших в ТОП-50`,
        text2: `Победитель розыгрыша пользователь с&nbsp;номером
            +7&nbsp;***&nbsp;***&nbsp;16&nbsp;55`,
      },
    },
  },
};
</script>

<style scoped>
.IphoneLottery {
  width: calc(100% - 24px);
  margin-left: auto;
  margin-right: auto;
}

.IphoneLottery__block {
  position: relative;
  height: 440px;
  padding: 32px 24px 48px;
  border-radius: 32px;
  background: #fff;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: #fff;
  background:
    url('./images/bg-star.svg') left bottom no-repeat,
    white;
  background-size: contain;
}

.IphoneLottery__p + .IphoneLottery__p {
  padding-top: 16px;
}

.IphoneLottery__text {
  font-size: 18px;
  line-height: 24px;
}

.IphoneLottery__p--note {
  color: #009164;
}

.IphoneLottery__product-img {
  @apply absolute;
  right: 0;
  bottom: -5px;
  right: 5px;
  width: 130px;
  height: 118px;
  background: url('./images/product.png') center no-repeat;
  background-size: contain;
}

@media screen and (min-width: 768px) {
  .IphoneLottery {
    max-width: 1188px;
    /* padding-top: 128px; */
  }

  .IphoneLottery__block {
    position: relative;
    padding: 72px 100px;
    height: 312px;
    border-radius: 64px;
    display: grid;
    grid-template-columns: 400px 1fr;
    grid-gap: 114px;
    background:
      url('./images/bg-star-desktop.svg') left bottom no-repeat,
      white;
  }

  .IphoneLottery__product-img {
    background-image: url('./images/product-desktop.png');
    left: 40px;
    bottom: -15px;
    right: auto;
    width: 186px;
    height: 168px;
  }
}
</style>
