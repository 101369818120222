<template>
  <GiftsProductCard
    class="PromocodeGiftProductContainer"
    :product="product"
    sticker
    @onGoProduct="openModal"
  />
</template>

<script>
// TODO: total price of cart when have this products
import GiftsProductCard from '@pure-ui/components/Gifts/GiftsProductCard/GiftsProductCard.vue';
import { PromocodeGiftService } from '@services/promocode-gift';
export default {
  components: { GiftsProductCard },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  methods: {
    openModal() {
      PromocodeGiftService.openModal();
    },
  },
};
</script>

<style></style>
