<template>
  <RenderlessAddressCreation v-slot="{ submit }">
    <div class="max-h-screen flex flex-col h-full -space-y-10">
      <!-- Кнопка назад -->
      <UiInlineIcon
        name="circle-back-btn"
        class="back-btn"
        @click.native="onBackClick"
      />

      <AddressMap
        v-if="!initLoading"
        ref="map"
        class="h-full"
        :prev-route="prevRoute"
        :input-coords="inputCoords"
        @getGeoObject="getGeoObject"
        @actionBegin="onMapAction"
        @onActionEnd="onFormFocused"
        @onMapInit="moveGeoLocationBtn"
      />

      <!-- Форма ввода адреса -->
      <div
        id="addressFormBlock"
        class="z-10 fixed bottom-0 w-full transition-all duration-1000"
        :style="formPosition"
        @touchstart="onFormFocused"
      >
        <div
          class="address-form-block-wrapper px-4 py-4 bg-white font-medium text-sirius-black-100 rounded-t-3xl"
        >
          <!-- Такой адрес уже сохранён -->
          <SavedAddress
            v-if="sameAsSavedAddress"
            :address="sameAsSavedAddress"
            @onSelectSaved="selectAddress"
            @onSelectOther="resetSavedAddress"
          />

          <template v-else>
            <!-- Расширенная доставка -->
            <ExtendedDeliveryPlank v-show="isExtendedDelivery" />

            <div class="flex items-center justify-between">
              <p class="ml-1">
                {{ $t('privateHouse') }}
              </p>
              <UiToggleSwitch v-model="isPrivateHouse" />
            </div>

            <div class="mt-4 space-y-2">
              <UiTextField
                v-if="id !== 'create'"
                v-model="name"
                :error-message="$t('requiredField')"
                :label="$t('addressNamePlaceholder')"
                type="text"
                maxlength="30"
                name="name"
                class="addressNamePlaceholder"
                :invalid="$v.name.$error"
                @onFocus="onFocusInputs"
              />
              <UiTextField
                v-if="id !== 'create'"
                v-model="name"
                :error-message="$t('requiredField')"
                :label="$t('addressNamePlaceholderSmall')"
                type="text"
                maxlength="30"
                name="name"
                class="addressNamePlaceholderSmall"
                :invalid="$v.name.$error"
                @onFocus="onFocusInputs"
              />

              <div class="relative" @click="openAddressList">
                <UiTextField
                  v-model="street"
                  :error-message="`${
                    isStreetValid ? '' : $t('selectBuildingOrHouse')
                  }`"
                  :label="$t('streetBuildingHouse')"
                  type="text"
                  name="street"
                  :invalid="$v.street.$error || !isStreetValid"
                />
                <div class="absolute top-0 w-full h-full bg-transparent"></div>
              </div>
            </div>

            <AddressForm
              id="new-address-from"
              class="mt-2"
              :initial-state="state"
              :is-private-house="isPrivateHouse"
              @onFocus="onFocusInputs"
              @onFormValidChange="isButtonEnabled = $event"
              @onSubmit="v => onSubmit(v, submit)"
            />

            <UiButton
              class="mt-6"
              size="LG"
              form="new-address-from"
              type="submit"
              :disabled="!isCorrectAddress"
            >
              {{ $t(id === 'create' ? 'continue' : 'saveChanges') }}
            </UiButton>
          </template>
        </div>
      </div>

      <!-- Модалка с вводом названия адреса, вроде "дом", "работа" -->
      <AddressNameModal
        v-model="showNameModal"
        :name="name"
        @update:name="v => (name = v)"
        @onSubmit="onNameSubmit(submit)"
      />
    </div>
  </RenderlessAddressCreation>
</template>

<script>
import { rFetchAddressById } from '@shared/services/api/apis';
import { required } from 'vuelidate/lib/validators';
import { AddressService } from '@services/address';
import { validationMixin } from 'vuelidate';
import { mapGetters } from 'vuex';

import AddressNameModal from '@pure-ui/components/Address/AddressNameModal/AddressNameModal.vue';
import SavedAddress from '@pure-ui/components/Address/SavedAddress/SavedAddress.vue';
import UiToggleSwitch from '@pure-ui/components/UiToggleSwitch/UiToggleSwitch.vue';
import UiInlineIcon from '@pure-ui/components/UiInlineIcon/UiInlineIcon.vue';
import UiTextField from '@pure-ui/components/UiTextField/UiTextField.vue';
import AddressForm from '@shared/components/address/AddressForm.vue';
import UiButton from '@/pure-ui/components/UiButton/UiButton.vue';

import AddressMap from './components/AddressMap.vue';
import ExtendedDeliveryPlank from './components/ExtendedDeliveryPlank.vue';
import RenderlessAddressCreation from '@components/address/RenderlessAddressCreation';

export default {
  name: 'AddressView',
  components: {
    RenderlessAddressCreation,
    ExtendedDeliveryPlank,
    AddressNameModal,
    UiToggleSwitch,
    UiInlineIcon,
    SavedAddress,
    UiTextField,
    AddressForm,
    AddressMap,
    UiButton,
  },
  mixins: [validationMixin],
  inject: ['toast'],
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from) vm.prevRoute = from;
    });
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    sameAsSavedAddress: null,
    isCorrectAddress: false,
    isStreetValid: true,
    isPrivateHouse: false,
    isExtendedDelivery: false,
    mapFocused: false,
    /**
     * Хранит валидный адрес, выбранный на карте
     * т. е. имеющий улицу, дом и находящийся в пределах города.
     * Может не входить в зоны доставки — тогда показывается.
     * Такой адрес всё равно можно сохранить, но будет
     * показана модалка "сюда пока не доставляем"
     */
    inputCoords: null,
    geoObject: '',
    initLoading: true,
    focusCount: 0,
    state: null,
    street: '',
    name: '',
    form: {},
    showNameModal: false,
    prevRoute: { name: 'main' },
    /**
     * Дизайнеры попросили пока выключить — им нужна старая логика
     */
    isButtonEnabled: false,
  }),
  validations: {
    street: {
      required,
    },
    name: {},
  },
  computed: {
    ...mapGetters(['isAuthorized']),
    ...mapGetters('user', ['deliveryAddressId', 'addressCount', 'addresses']),
    formPosition() {
      if (this.mapFocused && this.sameAsSavedAddress)
        return 'transform: translateY(0px)';
      if (this.mapFocused && this.isPrivateHouse)
        return 'transform: translateY(170px)';
      if (this.mapFocused && !this.isPrivateHouse)
        return 'transform: translateY(286px)';
      return 'transform: translateY(0px)';
    },
  },
  watch: {
    isExtendedDelivery: {
      handler(v) {
        const btn = document.getElementById('geoLocationBtn');
        btn.style.top = v ? '-8rem' : '-4rem';
      },
    },
  },
  mounted() {
    if (this.addressCount === 0)
      this.toast.show('Укажите адрес доставки', 'info');

    if (Number(this.id)) {
      rFetchAddressById(this.id, this.isAuthorized)
        .then(data => this.initializeState(data.data))
        .catch(err => this.toast.show(err.message, 'error'))
        .finally(() => (this.initLoading = false));
    } else {
      this.initLoading = false;
    }
  },
  methods: {
    moveGeoLocationBtn() {
      console.log('moveGeoLocationBtn');
      // It need to be triggered when clicked to btn
      // this.resetSavedAddress();
      const btn = document.getElementById('geoLocationBtn');
      const block = document.getElementById('addressFormBlock');
      console.log(btn, block);

      if (btn && block) {
        btn.className = btn.className + ' absolute right-4 -top-16';
        block.appendChild(btn);
      }
    },
    onFocusInputs() {
      if (this.focusCount > 0) return;
      this.focusCount += 1;
      window.scrollTo({
        top: window.innerHeight,
        behavior: 'smooth',
      });
    },
    onMapAction() {
      this.mapFocused = true;
    },
    onFormFocused() {
      this.mapFocused = false;
    },
    resetSavedAddress() {
      setTimeout(() => {
        this.sameAsSavedAddress = null;
      }, 100);
    },
    initializeState(address) {
      this.state = {
        flat_number: address.flat_number ?? '',
        entrance: address.entrance ?? '',
        floor: address.floor ?? '',
        comment: address.comment ?? '',
        doorbell: address.domofon_code ?? '',
        street: address.street ?? '',
        isPrivateHouse: address.is_private_home,
        lat: address.lat,
        long: address.long,
        name: address.name ?? '',
      };
      this.oldAddress = {
        street: address.street,
        lat: address.lat,
        long: address.long,
      };
      this.inputCoords = [address.lat, address.long];
      this.isPrivateHouse = address.is_private_home;
      this.name = address.name ?? '';
    },
    checkIsSavedAddress(address) {
      if (!this.addresses || this.addresses.length === 0) return null;

      const toString = x => (x !== null && x !== undefined ? x.toString() : '');

      const sameAddresses = [...this.addresses].filter(e => {
        if (this.isPrivateHouse) {
          return (
            toString(address.street) === toString(e.street) &&
            e.is_private_home === true
          );
        }
        return (
          toString(address.street) === toString(e.street) &&
          e.is_private_home === false &&
          toString(address.flat_number) === toString(e.flat_number) &&
          toString(address.entrance) === toString(e.entrance) &&
          toString(address.floor) === toString(e.floor)
        );
      });
      return sameAddresses && sameAddresses.length > 0
        ? sameAddresses[0]
        : null;
    },
    onSubmit(form, submitCB, fromNameSubmit = false) {
      this.form = {
        ...form,
        name: this.name || form.name,
        is_private_home: this.isPrivateHouse ? 1 : 0,
        domofon_code: form.doorbell,
      };

      if (!fromNameSubmit && this.id === 'create') {
        this.openAddressNamePopup();
        return;
      }

      this.closeAddressNamePopup();

      console.log('onSubmit', this.id, this.geoObject, this.form);
      submitCB(
        this.form,
        this.geoObject,
        this.id,
        this.isExtendedDelivery,
        'mobile_map'
      ).then(res => {
        console.log('CB, res', res);
        if (res?.savedAddress) {
          this.sameAsSavedAddress = res.savedAddress;
        } else this.$router.go(-1);
      });
    },
    onNameSubmit(onSubmitCB) {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.onSubmit(this.form, onSubmitCB, true);
    },

    /**
     * Вызывается на каждый клик по карте при установке маркера
     */
    getGeoObject({ address }) {
      this.resetSavedAddress();
      let { hasStreet, hasBuilding, isInCity, isInExtended, GeoObject } =
        address;
      this.isCorrectAddress = hasStreet && hasBuilding && isInCity;
      console.log('getGeoObject', address, this.isCorrectAddress);
      this.geoObject = this.isCorrectAddress ? GeoObject : '';

      this.street = GeoObject.name;
      this.isStreetValid =
        this.street.length === 0 ? true : this.isCorrectAddress;
      this.isExtendedDelivery = !!isInExtended;
    },
    openAddressList() {
      this.$refs.map.openSearchListModal(this.street);
      // this.onMapAction();
    },
    openAddressNamePopup() {
      this.onMapAction();

      this.showNameModal = true;
    },
    closeAddressNamePopup() {
      this.onFormFocused();
      this.showNameModal = false;
    },
    selectAddress() {
      AddressService.changeAddress(this.sameAsSavedAddress).finally(() => {
        this.$router.go(-1);
        this.resetSavedAddress();
      });
    },
    onBackClick() {
      this.$router.go(-1);
    },
  },
  i18n: {
    messages: {
      ru: {
        privateHouse: 'Частный дом',
        requiredField: 'Обязательное поле',
        streetBuildingHouse: 'Улица и здание/дом',
        continue: 'Продолжить',
        saveChanges: 'Сохранить изменения',
        updataAddress: 'Изменения сохранены',
        address: 'Адрес',
        addressNamePlaceholder: 'Название адреса, например «Дом», «Работа»',
        addressNamePlaceholderSmall: 'Название адреса, например «Дом»',
        selectBuildingOrHouse: 'Выберите здание или дом',
        addressCreated: 'Адрес добавлен',
      },
      kk: {
        privateHouse: 'Жер үй',
        requiredField: 'Міндетті жол',
        streetBuildingHouse: 'Көше, ғимарат, үй',
        continue: 'Жалғастыру',
        saveChanges: 'Өзгерістерді сақтау',
        updataAddress: 'Өзгерістер сақталды',
        address: 'Мекен-жай',
        addressNamePlaceholder: 'Мекен-жай аты, мысалға "Уй", "Жұмыс"',
        addressNamePlaceholderSmall: 'Мекен-жай аты, мысалға "Уй"',
        selectBuildingOrHouse: 'Үй немесе ғимарат таңдаңыз',
        addressCreated: 'Мекенжай қосылды',
      },
    },
  },
};
</script>

<style scoped>
.back-btn {
  @apply fixed z-20 top-5 left-4;
}

.addressNamePlaceholderSmall {
  display: none;
}
@media (max-width: 340px) {
  .addressNamePlaceholder {
    display: none;
  }
  .addressNamePlaceholderSmall {
    display: flex;
  }
}
</style>
