import { NY_SPECIAL_CATEGORY } from '@services/ny-contest/config';

export const NyContestModule = {
  namespaced: true,
  state: {
    winnersList: [],
    currentUser: null,
    forceShowTable: 0,
    nyEnabled: true,
    contestEnded: true,
    ranges: [
      {
        from: 3000,
        to: 4000,
        score: 1,
      },
      {
        from: 4001,
        to: 5000,
        score: 2,
      },
      {
        from: 5001,
        to: Number.MAX_SAFE_INTEGER,
        score: 5,
      },
    ],
  },
  getters: {
    nyEnabled(state) {
      return state.nyEnabled;
    },
    contestEnded(state) {
      return state.contestEnded;
    },
    currentUserPlace(state) {
      return state.currentUser?.place || 99999;
    },
    currentUserScore(state) {
      if (state.forceShowTable) return 1;
      return state.currentUser?.score || 0;
    },
    winnersList(state) {
      return state.winnersList;
    },
    /**
     * Сколько баллов начислится, если оформить заказ
     * с текущей корзиной
     */
    cartScore(state, getters, rootState, rootGetters) {
      return (
        rootGetters['cart/cart']?.nyCompetitionScore ||
        getters.activeRange.score
      );
    },
    /**
     * Возвращает название категории, за товары из который
     * начисляется дополнительный балл
     */

    additionalCategory() {
      return {
        enabled: true,
        link: `/category/${NY_SPECIAL_CATEGORY}`,
        categoryId: `${NY_SPECIAL_CATEGORY}`,
        ru: '+1 балл к розыгрышу',
        kk: 'Ұтыс ойынына +1 балл',
      };
    },
    /**
     * Условие, при котором показываются таблица с результатами
     * и текущие результаты п-ля
     * @returns {boolean}
     */
    showScore(state, getters, rootState, rootGetters) {
      // return getters['currentUserScore'];
      return rootGetters.isAuthorized;
    },
    activeRange(state, getters, rootState, rootGetters) {
      const price = rootGetters['cart/cartTotalPrice'];
      const defaultRange = {
        from: Number.MIN_SAFE_INTEGER,
        to: 3000,
        score: 0,
      };
      const range = state.ranges.find(r => price >= r.from && price <= r.to);
      return range || defaultRange;
    },
    hasAdditionalScore(state, getters) {
      return (
        getters.cartScore && getters.activeRange.score !== getters.cartScore
      );
    },
  },
  mutations: {
    SET_WINNERS_LIST(state, value) {
      state.winnersList = value;
    },
    SET_CURRENT_USER(state, value) {
      state.currentUser = value;
    },
    SET_FORCE_SHOW_TABLE(state, value) {
      state.forceShowTable = value;
    },
    SET_CONTEST_END(state, value) {
      state.contestEnded = value;
    },
  },
};
